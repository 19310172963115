import { Input, Table } from "antd";
import { useHistory } from "react-router-dom";
import Dashboard from "../seller-mgmt/Dashboard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPhotoshoots } from "../../redux/seller/seller.actions";
import { SearchOutlined } from "@ant-design/icons";

const { Search } = Input;

const Photoshoots = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { photoshoots } = useSelector((state) => state.brands);
  const { info } = useSelector((state) => state.user);

  const columns = [
    {
      align: "center",
      title: "Designer",
      dataIndex: "brand_name",
      key: "designer",
    },
    {
      align: "center",
      title: "First Name",
      dataIndex: "first_name",
      key: "firstName",
    },
    {
      align: "center",
      title: "Last Name",
      dataIndex: "last_name",
      key: "lastName",
    },
    {
      align: "center",
      title: "Email Address",
      dataIndex: "email",
      key: "emailAddress",
    },
    {
      align: "center",
      title: "Phone No.",
      dataIndex: "brand_phone",
      key: "phoneNumber",
      render: (text, record) => (
        <div>
          {record.phone_dial}
          {record.phone_number}
        </div>
      ),
    },
    {
      align: "center",
      title: "Photoshoot Plan",
      dataIndex: "photoshoot_plan",
      key: "photoshootPlan",
    },
  ];

  useEffect(() => {
    dispatch(getPhotoshoots(info.token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dashboard
      headerTitle="The Designer Management Portal"
      // //headerSubtitle="Get inisights on all users using your app"
    >
      <div className="manage-payment-container">
        <div className="manage-listing-all-content">
          <div className="manage-payment-coverall-container">
            <div className="manage-payment-body-content-header-container">
              <div className="manage-payment-body-content-header-title">
                New Requests
              </div>
              <div
                style={{
                  width: "50%",
                  height: "48px",
                  margin: "24px 0px 32px",
                }}
              >
                <Search
                  className="search"
                  allowClear
                  enterButton="Search"
                  // onSearch={onSearch}
                  placeholder="Enter designer name"
                  prefix={<SearchOutlined />}
                />
              </div>
              {photoshoots && (
                <div>
                  {
                    photoshoots?.filter(
                      (photoshoot) => !photoshoot.photoshoot_date
                    )?.length
                  }{" "}
                  requests
                </div>
              )}
            </div>

            <div className="manage-payment-body-container">
              <div className="manage-payment-body-content">
                <div className="manage-payment-body-content-section">
                  <Table
                    className="manage-payment-transaction-table"
                    dataSource={photoshoots?.filter(
                      (photoshoot) => !photoshoot.photoshoot_date
                    )}
                    columns={columns}
                    rowKey={(record) => record.id}
                    rowClassName="seller-row"
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: () => {
                          history.push(`/new-photoshoot/${record.id}`);
                        },
                      };
                    }}
                    pagination={{
                      position: ["bottomCenter"],
                      pageSize: 50,
                      total: photoshoots?.length,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <style jsx="true">{`
          .manage-payment-container {
            font-family: DomaineSansText-Light;
            color: black;
            margin-top: 16px;
          }
          .ant-table-thead > tr > th {
            background-color: #000;
            border: 0px;
            color: #fff;
          }
          .manage-payment-coverall-container {
          }
          .manage-listing-all-content {
            position: relative;
          }

          .manage-payment-adspace {
            background-image: url("images/seller-stats-banner.png");
            height: 211px;
            background-repeat: no-repeat;
            width: 80%;
            background-position: bottom;
            background-color: black;
            margin: auto;
          }

          .manage-payment-body-container {
            display: flex;
            justify-content: space-between;
          }
          .manage-payment-body-content-header-container {
            margin-bottom: 24px;
            width: 100%;
          }
          .manage-payment-body-content-header-title {
            font-size: 32px;
            font-weight: bold;
            text-align: left;
          }
          .manage-payment-body-content-header-subtitle {
            font-size: 16px;
            color: #707070;
          }
          .manage-payment-body-sidebar-add-listing-button {
            height: 47px;
            background: white;
            color: black;
            font-size: 16px;
            display: flex;
            align-items: center;
            gap: 8px;
          }

          .manage-payment-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 70px;
          }

          .manage-payment-body-content-top-actions {
            display: flex;
            gap: 40px;
            margin-bottom: 24px;
            align-items: center;
          }
          .top-actions-checkbox-container {
            padding: 5px;
            border: 1px solid #d4d4d4;
            border-radius: 4px;
          }
          .pagination-nav-text {
            font-family: "DomaineSansText-Light";
          }
          .pagination-nav-text:hover {
            color: #800000;
          }
          .activate-delete-button {
            background: black;
            color: white;
          }

          .manage-payment-body-content-header-subtitle {
            font-size: 16px;
            color: #707070;
          }

          .manage-payment-body-content {
            width: 100%;
          }
          .manage-payment-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 70px;
            // gap: 21px;
          }

          .manage-payment-body-content-top-actions {
            display: flex;
            gap: 40px;
            margin-bottom: 24px;
          }
          .top-actions-checkbox-container {
            padding: 5px;
            border: 1px solid #d4d4d4;
            border-radius: 4px;
          }
          .pagination-nav-text {
            font-family: "DomaineSansText-Light";
          }
          .pagination-nav-text:hover {
            color: #800000;
          }
          .activate-delete-button {
            background: black;
            color: white;
          }

          .no-listings-to-show {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 500px;
            font-weight: bold;
            font-size: 18px;
          }
          .no-listings-to-show-link {
            text-decoration: underline;
          }
          .no-listings-to-show-link:hover {
            cursor: pointer;
            color: #800000;
          }
          .ant-select-item-option-selected:not(
              .ant-select-item-option-disabled
            ) {
            background: white;
          }

          .manage-payment-payment-summary-section {
            display: flex;
            justify-content: space-between;
            width: 88%;
          }
          .payment-summary-grey-box {
            background: #f9f9f9;
            padding: 24px;
          }
          .payment-summary-pink-box {
            background: rgba(128, 0, 0, 0.05);
            padding: 24px;
            width: 450px;
          }
          .payment-summary-big-number {
            font-size: 24px;
            font-weight: bold;
            font-family: "DomaineSansText-Black";
            margin-top: 8px;
          }
          .payment-summary-pink-box-button {
            background: black;
            color: white;
            height: 47px;
            font-size: 16px;
            margin-top: 4px;
          }
          .payment-summary-underline-link {
            margin-top: 10px;
            padding: 16px 0;
            border-top: 1px solid #d4d4d4;
            font-size: 12px;
            color: #6f6f6f;
            text-decoration: underline;
          }
          .manage-payment-body-content-section-title {
            font-size: 18px;
            margin-bottom: 16px;
            font-weight: bold;
          }

          .manage-payment-body-content-trade-section-title {
            font-size: 18px;
            font-weight: bold;
          }

          .trade-summary-big-number {
            font-weight: bold;
            font-family: "DomaineSansText-Black";
          }
          .manage-payment-body-content-section-subtitle {
            font-size: 16px;
          }
          .trade-summary-date-select > .ant-select-selector {
            // background-color: black !important;
            // color: white;
            height: 40px !important;
            display: flex !important;
            align-items: center !important;
            width: 120px !important;
          }
          .trade-summary-date-select > .ant-select-arrow {
            // color: white;
          }
          .manage-payment-body-content-section {
            margin-top: 40px;
          }
          .recent-transaction-image {
            background-repeat: no-repeat;
            width: 80px;
            height: 80px;
            background-size: cover;
          }
          .ant-input-search
            > .ant-input-group
            > .ant-input-group-addon:last-child
            .ant-input-search-button {
            background: #000;
            border: 1px solid #000;
            color: #fff;
            height: 48px;
            font-family: "DomaineSansText-Light";
            padding: 0 24px;
          }
          .ant-input-search
            .ant-input-group
            .ant-input-affix-wrapper:not(:last-child) {
            height: 48px;
          }
          .seller-row {
            cursor: pointer;
          }
          .date {
            width: 122px;
            height: 48px;
          }
          .txt-total-brands {
            font-size: 16px;
            line-height: 24px;
            margin-top: 35px;
          }
        `}</style>
      </div>
    </Dashboard>
  );
};

export default Photoshoots;
