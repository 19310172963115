import { useEffect } from "react";
import { Avatar, Menu, Dropdown, Layout, Input } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { userActionCreators } from "../redux/user";
import { brandActionCreators } from "../redux/seller";

const { Header } = Layout;
const { Search } = Input;

const HeaderComponent = ({
  search = true,
  headerTitle,
  headerSubtitle,
  mainDashboard,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { logout } = bindActionCreators(userActionCreators, dispatch);
  const { searchBrand } = bindActionCreators(brandActionCreators, dispatch);
  const user = useSelector((state) => state.user);
  const { searchResult, showResult } = useSelector((state) => state.brands);

  const signOut = () => {
    logout();
    history.push("/");
  };

  const onSearch = async (value) => {
    searchBrand(user.info.token, { keyword: value });
  };

  useEffect(() => {}, [user]);
  const accountMenu = (
    <Menu className="account-menu">
      {user.info.role === 1 && (
        <Menu.Item key="0">
          <h4 className="menu-btn" onClick={() => history.push("/new")}>
            Add Admin
          </h4>
        </Menu.Item>
      )}
      <Menu.Item key="0">
        <h4 className="menu-btn" onClick={() => history.push("/")}>
          Admin Portal
        </h4>
      </Menu.Item>

      <Menu.Item key="1">
        <h4
          className="menu-btn"
          onClick={() => history.push("/settings", user.info)}
        >
          Account Settings
        </h4>
      </Menu.Item>
      <Menu.Item key="2">
        <h4 className="menu-btn" onClick={signOut}>
          Sign Out
        </h4>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="header">
      {/* Search in Navbar made nonfuctional */}
      {search && !search && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ width: "400px", height: "54px" }}>
            <Search
              className="search"
              allowClear
              onSearch={onSearch}
              placeholder="Search For Brands"
            />
          </div>
          {showResult ? (
            searchResult.length > 0 ? (
              searchResult.map((brand) => (
                <p
                  className="txt-search"
                  onClick={() => history.replace(`/${brand.slug}/info`, brand)}
                >
                  {brand.store_name}
                </p>
              ))
            ) : (
              <p className="txt-search">No results found</p>
            )
          ) : (
            ""
          )}
        </div>
      )}

      <img
        className="header-portal-logo"
        src="/images/logo-white-no-tagline.png"
        alt="logo"
        onClick={() => history.push("/")}
      />

      <div className="header-nav-title-container">
        <div className="header-nav-title">{headerTitle ? headerTitle : ""}</div>
        <div className="header-nav-subtitle">
          {headerSubtitle ? headerSubtitle : ""}
        </div>
      </div>

      <div className="menu">
        {/* <Badge count={5}>
          <img
            style={{ width: "24px" }}
            src="/images/bell-icon.png"
            alt="bell"
          />
        </Badge> */}

        <Avatar
          src={
            user.settings.imageUrl
              ? `${user.settings.imageUrl}`
              : "/images/avatar.jpg"
          }
          size={35}
          style={{ marginLeft: 60 }}
        />

        <div className="links">
          <Dropdown overlay={accountMenu} placement="bottomLeft">
            <div className="acct-name">
              {user.info.fname} {user.info.lname}
              <DownOutlined style={{ marginLeft: 10 }} />
            </div>
          </Dropdown>
        </div>
      </div>

      <style jsx="true">{`
        .header {
          background: ${mainDashboard ? "#fff" : "#f7f7f7"};
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: fit-content;
          padding: 16px 40px;
          top: 0;
          width: 100%;
          position: fixed;
          z-index: 200;
        }
        .header-portal-logo {
          cursor: pointer;
          width: 120px;
          filter: invert(1);
        }
        .menu {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: max-content;
          z-index: 300;
        }
        .acct-name,
        .acct-name:hover {
          color: #000000;
          font: normal normal 16px/19px DomaineSansText-Regular;
          margin-left: 10px;
          cursor: pointer;
        }
        .menu-btn {
          color: #677189;
          font: normal normal 14px/146.16% DomaineSansText-Regular;
        }
        .menu-btn:hover {
          color: #be002b;
          font-weight: 700;
        }
        .ant-input-search
          .ant-input-group
          .ant-input-affix-wrapper:not(:last-child) {
          height: 45px;
        }

        .ant-badge-count {
          background: black;
        }
        .header-nav-title-container {
          line-height: normal;
          font-family: "DomaineSansText-Regular";
          font-size: 18px;
          text-align: center;
          position: absolute;
          width: 100%;
        }
        .header-nav-title {
          font-size: 18px;
          text-transform: uppercase;
        }
        .header-nav-subtitle {
          font-size: 14px;
          margin-top: 4px;
        }
      `}</style>
    </Header>
  );
};

export default HeaderComponent;
