import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { brandActionCreators } from "../../redux/seller";
import { ordersActionCreators } from "../../redux/orders";
import {
  Button,
  message,
  Rate,
  Select,
  Form,
  Row,
  Modal,
  Col,
  Switch,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import ListingView from "../seller/ListingView";
// import { useMediaQuery } from "react-responsive";;
import { useHistory } from "react-router-dom";
import OrderView from "./OrderView";
import Dashboard from "../seller-mgmt/Dashboard";
import formatter from "../../utils/formatter";
import { countryInfo } from "../../assets/constants";

const { Option } = Select;

const SellerInformation = () => {
  const isTabletOrMobile = false;
  const history = useHistory();
  const dispatch = useDispatch();
  const [blockForm] = Form.useForm();

  const id = window.location.pathname.split("/")[2];

  const [showBlockModal, setShowBlockModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [publishModalInfoMessageShow, setPublishModalInfoMessageShow] =
    useState(false);

  const {
    fetchBrand,
    fetchBrandDetails,
    fetchBrandListings,
    activateBrand,
    deactivateBrand,
    blockBrand,
    unblockBrand,
    fetchProduct,
    deleteProduct,
    togglePublish,
  } = bindActionCreators(brandActionCreators, dispatch);
  const { fetchSellerPendingOrders } = bindActionCreators(
    ordersActionCreators,
    dispatch
  );

  const { info } = useSelector((state) => state.user);
  const { pendingOrdersObj } = useSelector((state) => state.orders);
  const {
    brand,
    brandDetails: details,
    brandProducts: listings,
    brandSponsoredItems: sponsoredItems,
  } = useSelector((state) => state.brands);

  const orders = pendingOrdersObj?.data;

  let statusColor;
  let color;
  if (brand?.status === "activated") {
    color = "#10554A";
    statusColor = "rgba(30, 174, 152, 0.4)";
  }
  if (brand?.status === "blocked") {
    color = "#D00202";
    statusColor = "rgba(208, 2, 2, 0.2)";
  }
  if (brand?.status === "on vacation") {
    color = "#DBA820";
    statusColor = "rgba(253, 202, 64, 0.2)";
  }
  if (brand?.status === "deactivated") {
    color = "#F6BBBB";
    statusColor = "#800000";
  }

  const country = countryInfo.find((item) => item.code === brand?.country);

  const editItem = async (id) => {
    const res = await fetchProduct(info.token, id);
    if (res.status === "ok") {
      history.push("/edit-listing", { state: "listing" });
    }
  };

  const deleteItem = async (itemId) => {
    const res = await deleteProduct(info.token, [itemId], "", id);
    if (res.status === "ok") {
      message.success(res.message);
    }
  };

  const activateStore = async (publish) => {
    setTimeout(() => {
      setShowPublishModal(false);
      setPublishModalInfoMessageShow(false);
    }, 750);

    const res = await activateBrand(
      info.token,
      brand.store_id,
      brand.sellerId,
      publish
    );
    if (res.status === "ok") {
      message.success(res.message);
    }
    if (res.status === "not ok") {
      message.error(res.message);
    }
  };

  const deactivateStore = async () => {
    const res = await deactivateBrand(
      info.token,
      brand.store_id,
      brand.sellerIdx
    );
    if (res.status === "ok") {
      message.success(res.message);
    }
    if (res.status === "not ok") {
      message.error(res.message);
    }
  };

  const blockStore = async (val) => {
    const res = await blockBrand(info.token, {
      sellerId: brand.sellerId,
      ...val,
    });
    if (res.status === "ok") {
      message.success(res.message);
      setShowBlockModal(false);
    }
    if (res.status === "not ok") {
      message.error(res.message);
    }
  };
  const unblockStore = async () => {
    const res = await unblockBrand(info.token, brand.sellerId);
    if (res.status === "ok") {
      message.success(res.message);
    }
    if (res.status === "not ok") {
      message.error(res.message);
    }
  };

  const toggleState = async () => {
    const res = await togglePublish(info.token, brand.sellerId);
    if (res.status === "ok") {
      message.success(res.message);
    }
    if (res.status === "not ok") {
      message.error(res.message);
    }
  };

  useEffect(() => {
    fetchBrand(info?.token, id);
    fetchBrandDetails(info?.token, id);
    fetchBrandListings(info?.token, id, 1);
    fetchSellerPendingOrders(info?.token, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Dashboard
      headerTitle="The Designer Management Portal"
      //headerSubtitle="Get inisights on all users using your app"
      dashboardBgColor="#f7f7f7"
    >
      <div className="dashboard-container">
        <div className="manage-listing-all-content">
          <div className="dashboard-coverall-container">
            {/* <div className="dashboard-adspace"></div> */}

            <div className="dashboard-body-main">
              {/* <div
                style={{
                  width: "50%",
                  height: "48px",
                  margin: "16px 0px 24px",
                }}
              >
                <Search
                  className="search"
                  allowClear
                  enterButton="Search"
                  // onSearch={onSearch}
                  placeholder="Enter seller name"
                  prefix={<SearchOutlined />}
                />
              </div> */}

              <div
                style={{ marginBottom: "16px", cursor: "pointer" }}
                onClick={() => history.goBack()}
              >
                <svg
                  width="28"
                  height="10"
                  viewBox="0 0 28 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.94997 5.35647C0.994502 5.47131 1.0613 5.57678 1.14684 5.66583L4.89684 9.41583C5.07263 9.59278 5.31285 9.69356 5.56248 9.69356C5.81211 9.69356 6.05232 9.59278 6.22812 9.41583C6.40508 9.24004 6.50586 8.99982 6.50586 8.75019C6.50586 8.50056 6.40508 8.26035 6.22812 8.08455L4.07187 5.93769H26.6562C27.1742 5.93769 27.5937 5.51817 27.5937 5.00019C27.5937 4.48221 27.1742 4.06269 26.6562 4.06269H4.07187L6.22812 1.91583C6.5961 1.54785 6.5961 0.952559 6.22812 0.584579C5.86014 0.216599 5.26485 0.216599 4.89687 0.584579L1.14687 4.33458C1.06133 4.42364 0.994532 4.52911 0.95 4.64394C0.901952 4.75644 0.876173 4.87714 0.875 5.00019C0.876172 5.12324 0.901953 5.24394 0.95 5.35644L0.94997 5.35647Z"
                    fill="black"
                  />
                </svg>

                <span
                  style={{
                    fontFamily: "DomaineSansText-Light",
                    marginLeft: "8px",
                  }}
                >
                  Back To All Designers
                </span>
              </div>

              <div className="dashboard-body-content-header seller-info-section">
                <div className="dashboard-body-content-header-container">
                  <div
                    className="dashboard-body-content-header-title"
                    // onClick={() => {
                    //   setPhoneVerifyModalVisible(true);
                    // }}
                  >
                    Designer Information
                  </div>
                </div>
              </div>

              <div className="seller-info-section personal-info-section">
                <div className="personal-info-first-container">
                  <div
                    style={{
                      display: "flex",
                      gap: "24px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="personal-info-profile-image"
                      style={{
                        backgroundImage: `url(${brand?.logo})`,
                      }}
                    ></div>
                    <div>
                      <div
                        style={{
                          fontSize: "18px",
                          fontFamily: "DomaineSansText-Regular",
                        }}
                      >
                        {brand?.store_name}
                        <span style={{ marginLeft: "4px" }}>
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.5 12.5H13.125"
                              stroke="#800000"
                              stroke-width="0.875"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M10.3125 2.18764C10.5611 1.939 10.8984 1.79932 11.25 1.79932C11.4241 1.79932 11.5965 1.83361 11.7574 1.90024C11.9182 1.96687 12.0644 2.06453 12.1875 2.18764C12.3106 2.31076 12.4083 2.45691 12.4749 2.61777C12.5415 2.77863 12.5758 2.95103 12.5758 3.12514C12.5758 3.29925 12.5415 3.47166 12.4749 3.63251C12.4083 3.79337 12.3106 3.93953 12.1875 4.06264L4.375 11.8751L1.875 12.5001L2.5 10.0001L10.3125 2.18764Z"
                              stroke="#800000"
                              stroke-width="0.875"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                      <div style={{ marginTop: "4px" }}>
                        <Rate
                          allowHalf
                          disabled
                          value={brand?.rating}
                          style={{ color: "#800000", fontSize: 14 }}
                        />
                      </div>
                      <div style={{ marginTop: "4px" }}>
                        {details?.brandCategory?.map((category) => (
                          <span style={{ marginRight: 10 }}>{category}</span>
                        ))}
                      </div>
                      <div
                        className="store-header-banner-text-header-flex-section"
                        style={{ gap: "16px", padding: "0" }}
                      >
                        <div className="store-header-banner-text-header-flex-section">
                          <img src={country?.flag} alt={country?.name} />
                          <div>{country?.name}</div>
                        </div>
                        <div className="store-header-banner-text-header-flex-section">
                          <svg
                            width="19"
                            height="15"
                            viewBox="0 0 19 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.3696 14.9619H8.4059L0.652344 7.20219V2.3954L3.00827 0.0375977H7.81122L8.75764 0.987305L7.86436 1.8813L7.2874 1.30387H3.53209L1.91761 2.91964V6.67795L8.92972 13.6957H9.84577L16.8478 6.68555V2.92724L15.2307 1.30894L11.4805 1.31147L5.60966 7.20472L4.71385 6.31073L10.9541 0.0477279L15.7546 0.0426628L18.113 2.40553V7.20979L10.3696 14.9619Z"
                              fill="#800000"
                            />
                          </svg>
                          <div>({brand?.favourite})</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="personal-info-status-tag">
                    {brand?.status}
                  </div>
                </div>

                <div className="personal-info-second-container">
                  <div className="seller-info-payment-title">
                    Personal Information
                  </div>
                  <div className="personal-info-second-container-content">
                    <table>
                      <tbody>
                        <tr>
                          <td className="personal-info-label">Name</td>
                          <td>{brand?.sellerName}</td>
                        </tr>
                        <tr>
                          <td className="personal-info-label">Email Address</td>
                          <td>{brand?.sellerEmail}</td>
                        </tr>
                        <tr>
                          <td className="personal-info-label">Phone Number</td>
                          <td>{brand?.bizPhone}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      style={{
                        lineHeight: "2",
                        width: !isTabletOrMobile ? "300px" : "100%",
                        fontFamily: "DomaineSansText-Regular",
                      }}
                    >
                      <div style={{ color: "#6f6f6f" }}>Designer Address</div>
                      {brand?.sellerAddress}
                    </div>
                  </div>

                  <div
                    style={{
                      textAlign: "right",
                      margin: "24px 0",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      className="ant-btn dashboard-body-quick-action-button"
                      onClick={() => history.push(`/seller/${brand.slug}/settings`)}
                    >
                      Store Settings
                    </Button>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                        fontWeight: "bold",
                        fontSize: "16px",
                        marginLeft: "2em",
                      }}
                    >
                      <div>Publish Store</div>
                      <Switch
                        checked={brand?.storePublished}
                        onChange={() => toggleState()}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="dashboard-body-container">
                <div className="dashboard-body-content">
                  <div className="seller-info-section">
                    <div className="dashboard-body-bold-numbers-container">
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-earned">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {/* {formatter(
                        settlementInfo?.last_90_days,
                        rate,
                        code,
                        indicator
                      )} */}
                          {details?.totalSale}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          TOTAL SALES ON LOSODE
                        </div>
                      </div>
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-listed">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {/* {totalProducts} */}
                          {details?.rejectedOrder}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          REJECTED ORDERS
                        </div>
                      </div>
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-sold">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {details?.pendingOrder}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          PENDING ORDERS
                        </div>
                      </div>
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-sold">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {details?.growthRate}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          GROWTH RATE
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="seller-info-section seller-info-payment-container">
                    <div>
                      <div className="seller-info-payment-title">
                        Payment Summary
                      </div>
                      <div className="seller-info-payment-box-container">
                        <div className="seller-info-payment-box">
                          <div className="seller-info-payment-box-label">
                            PENDING BALANCE
                          </div>
                          <div className="seller-info-payment-box-number">
                            {formatter(details?.pending_balance)}
                          </div>
                          {/* <div className="seller-info-payment-extra">
                            Update account details
                          </div> */}
                        </div>
                        <div className="seller-info-payment-box">
                          <div className="seller-info-payment-box-label">
                            NEXT PAYMENT DUE
                          </div>
                          <div className="seller-info-payment-box-number">
                            {formatter(details?.payment_due)}
                          </div>
                          <div className="seller-info-payment-box-extra">
                            TO BE PAID:{" "}
                            <span
                              style={{ fontFamily: "DomaineSansText-Black" }}
                            >
                              {details?.next_pay_date}
                            </span>
                          </div>
                        </div>
                        <div className="seller-info-payment-box">
                          <div style={{ marginBottom: "8px" }}>
                            Account Name: {details?.accountName}
                          </div>
                          <div style={{ marginBottom: "8px" }}>
                            Account Number: {details?.accountNumber}
                          </div>
                          <div style={{ marginBottom: "8px" }}>
                            Bank Name: {details?.bankName}
                          </div>
                          <div className="seller-info-payment-extra">
                            Payment schedule: Fortnightly
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="dashboard-body-quick-action-container seller-info-section">
                    <div className="dashboard-body-quick-action-title">
                      Quick Actions
                    </div>
                    <div className="dashboard-body-quick-action-button-container">
                      <Button
                        icon={
                          <PlusOutlined
                            style={{
                              fontSize: !isTabletOrMobile ? "19px" : "15px",
                            }}
                          />
                        }
                        className="dashboard-body-quick-action-button"
                        onClick={() =>
                          history.push("/add-product", {
                            brand: brand?.store_name,
                            sellerId: brand?.sellerId,
                          })
                        }
                      >
                        List A New Item
                      </Button>
                      {brand?.status === "deactivated" && (
                        <Button
                          className="dashboard-body-quick-action-button"
                          onClick={() => setShowPublishModal(true)}
                        >
                          Activate Store
                        </Button>
                      )}
                      {brand?.status === "activated" && (
                        <Button
                          className="dashboard-body-quick-action-button"
                          onClick={deactivateStore}
                        >
                          Deactivate Store
                        </Button>
                      )}
                      <Button
                        className="dashboard-body-quick-action-button"
                        onClick={() =>
                          window.open(`/${brand.slug}/preview`, "_blank")
                        }
                      >
                        View Store
                      </Button>
                      {/* <Button
                        className="dashboard-body-quick-action-button"
                        // onClick={() => navigate("/list-item")}
                      >
                        Orders
                      </Button> */}
                      {/* <Button
                        className="dashboard-body-quick-action-button"
                        onClick={() =>
                          history.push(`/seller/${brand.slug}/settings`)
                        }
                      >
                        Store Settings
                      </Button> */}
                      <Button
                        className="dashboard-body-quick-action-button"
                        onClick={() => history.push(`/seller/${id}/discounts`)}
                      >
                        Discounts
                      </Button>

                      {brand?.status !== "blocked" ? (
                        <Button
                          className="dashboard-body-quick-action-button"
                          onClick={() => setShowBlockModal(true)}
                        >
                          Block Designer
                        </Button>
                      ) : (
                        <Button
                          className="dashboard-body-quick-action-button"
                          onClick={unblockStore}
                        >
                          Unblock Designer
                        </Button>
                      )}
                      <Button
                        className="dashboard-body-quick-action-button"
                        onClick={() =>
                          history.push(`/seller/${id}/approve-listings`)
                        }
                      >
                        Approve Listings
                      </Button>
                    </div>
                  </div>

                  <div className="dashboard-body-content-header seller-info-section">
                    <div className="dashboard-body-content-header-container">
                      <div className="dashboard-body-content-header-title">
                        Order Information
                      </div>
                    </div>
                  </div>

                  <div className="dashboard-body-orders-section seller-info-section">
                    <div
                      className="dashboard-body-orders-title"
                      style={{ position: "relative" }}
                    >
                      <div>New Orders</div>
                    </div>

                    <div className="dashboard-body-content-listings-container">
                      {orders?.length > 0 ? (
                        orders
                          .slice(0, 4)
                          .map((order) => (
                            <OrderView
                              key={order.sale_id}
                              product={order}
                              type="manage orders"
                              productTag="New"
                            />
                          ))
                      ) : (
                        <div className="no-listings-to-show">
                          <div>
                            <div>Designer currently has 0 new orders</div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="dashboard-body-orders-section seller-info-section">
                    <div
                      className="dashboard-body-orders-title"
                      style={{ position: "relative" }}
                    >
                      <div>Flagged Orders</div>
                    </div>
                    {/* <div
                    className="dashboard-body-events-subtitle"
                    style={{ marginBottom: "24px" }}
                  >
                    Go to manage orders to view all of your orders and tell us
                    when the orders have been sent out so that we can let your
                    buyers know
                  </div> */}

                    <div className="dashboard-body-content-listings-container">
                      {isTabletOrMobile ? (
                        orders.data.slice(0, 4).map((order) => (
                          <OrderView
                            // key={order.id}
                            // product={order}
                            type="manage orders"
                            flagged
                          />
                        ))
                      ) : (
                        <div className="no-listings-to-show">
                          <div>
                            <div>Designer currently have 0 flagged orders</div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="dashboard-body-orders-section seller-info-section">
                    <div
                      className="dashboard-body-orders-title"
                      style={{ position: "relative" }}
                    >
                      <div>Manage Listings</div>
                      <div
                        style={{
                          color: "#800000",
                          textDecoration: "underline",
                          fontSize: "12px",
                          position: "absolute",
                          right: 0,
                          top: 0,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          history.push(`/${brand.slug}/manage-listings`)
                        }
                      >
                        view more
                      </div>
                    </div>

                    <div className="dashboard-body-content-listings-container">
                      {listings?.data?.length > 0 ? (
                        listings?.data?.slice(0, 4).map((listing) => (
                          <ListingView
                            editItem={() => editItem(listing.product_id)}
                            deleteItem={() => deleteItem(listing.product_id)}
                            key={listing.product_id}
                            product={listing}
                            // productTag={"new"}
                          />
                        ))
                      ) : (
                        <div className="no-listings-to-show">
                          <div>
                            <div>Designer currently has 0 new Listings</div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="dashboard-body-orders-section seller-info-section">
                    <div className="dashboard-body-orders-title">
                      Sponsored Items
                    </div>

                    <div className="dashboard-body-content-listings-container">
                      {sponsoredItems?.length > 0 ? (
                        sponsoredItems
                          ?.slice(0, 4)
                          .map((item) => (
                            <ListingView
                              key={item.id}
                              product={item}
                              type="Dashboard"
                            />
                          ))
                      ) : (
                        <div className="no-listings-to-show">
                          <div>
                            <div>Designer currently has 0 sponsored items</div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          centered
          visible={showBlockModal}
          onCancel={() => setShowBlockModal(false)}
          footer={null}
          className="shopBuyerModal"
          width={"60%"}
          maskClosable={false}
        >
          <div style={{ marginBottom: "40px" }}>
            <div className="shop-buyer-modal-form-title">
              Block {brand?.store_name}
            </div>
            <div>Kindy select reason for blocking</div>
          </div>

          <Form
            form={blockForm}
            name="blockForm"
            scrollToFirstError
            layout="vertical"
            requiredMark={false}
            onFinish={blockStore}
          >
            <Row className="form-row" gutter={[24, 12]}>
              <Col span={12}>
                <Form.Item
                  name="reason"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="block-select"
                    placeholder="Select reason for blocking designer"
                  >
                    <Option value="Designer is using other Designers pictures">
                      Designer is using other Designers pictures
                    </Option>
                    <Option value="Selling defective/low quality items">
                      Selling defective/low quality items
                    </Option>
                    <Option value="Designer has cancelled/rejected more than 5 orders this month">
                      Designer has cancelled/rejected more than 5 orders this
                      month
                    </Option>
                    <Option value="Designer is suspected of being fraudulent">
                      Designer is suspected of being fraudulent
                    </Option>
                    <Option value="Designer is taking business off the Losode platform">
                      Designer is taking business off the Losode platform
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Button className="btn-save" htmlType="submit">
                  Block Seller
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          centered
          visible={showPublishModal}
          onCancel={() => {
            setShowPublishModal(false);
            setPublishModalInfoMessageShow(false);
          }}
          footer={null}
          // className="shopBuyerModal"
          // width={"60%"}
          style={{ fontFamily: "DomaineSansText-Light" }}
        >
          {!publishModalInfoMessageShow ? (
            <div>
              <div style={{ marginBottom: "16px", fontSize: "20px" }}>
                Do you want to publish this store as well?
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontSize: "16px",
                }}
              >
                <Button
                  onClick={() => {
                    setPublishModalInfoMessageShow(true);
                    activateStore("No");
                  }}
                  style={{ marginRight: "12px", width: "80px" }}
                >
                  No
                </Button>
                <Button
                  onClick={() => {
                    setShowPublishModal(false);
                    activateStore("Yes");
                  }}
                  style={{ width: "80px", color: "white", background: "black" }}
                >
                  Yes
                </Button>
              </div>
            </div>
          ) : (
            <div style={{ fontSize: "20px" }}>
              You can {brand?.storePublished ? "publish" : "unpublish"} this
              store anytime by using the publish store toggle button
            </div>
          )}
        </Modal>

        <style jsx="true">{`
          .dashboard-container {
            font-family: DomaineSansText-Light;
          }

          .manage-listing-all-content {
            position: relative;
          }
          .dashboard-coverall-container {
          }
          .dashboard-body-main {
            padding-bottom: 40px;
            margin-top: 16px;
          }
          .dashboard-adspace {
            background-image: url("images/seller-stats-banner.png");
            height: 211px;
            background-repeat: no-repeat;
            width: 80%;
            background-position: bottom;
            background-color: black;
            margin: auto;
          }
          .dashboard-body-content-header-container {
          }
          .dashboard-body-content-header {
            display: flex;
            justify-content: space-between;
          }
          .dashboard-body-content-header-title {
            font-size: 24px;
            font-weight: bold;
          }
          .dashboard-body-content-header-subtitle {
            font-size: 14px;
            color: #707070;
          }
          .dashboard-body-bold-numbers-container {
            display: flex;
            gap: 30px;
            width: 100%;
            margin: 16px 0;
          }
          .dashboard-body-bold-numbers-item {
            width: 32%;
            background: black;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 30px 10px;
          }
          .dashboard-body-bold-numbers-item-number {
            font-family: "DomaineSansText-Black";
            font-size: 24px;
          }
          .dashboard-body-bold-numbers-item-text {
            font-size: 12px;
          }
          .dashboard-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
          }
          .dashboard-body-alert-box-container {
            margin-bottom: 50px;
          }
          .dashboard-body-alert-box {
            margin: 16px 0;
            background: #800000;
            color: white;
            padding: 10px;
            width: 100%;
          }
          .dashboard-body-alert-box-title {
            font-size: 14px;
            text-decoration: underline;
          }
          .alert-box-dispute {
            font-size: 14px;
          }
          .dashboard-body-orders-section {
            margin-bottom: 50px;
          }
          .dashboard-body-orders-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 24px;
            padding-left: 5px;
            margin-top: 8px;
          }
          .dashboard-body-community-container {
            background: #ffecb3;
            padding: 16px 10px;
            width: 100%;
            margin-bottom: 50px;
          }
          .dashboard-body-community-title {
            font-size: 20px;
            font-weight: bold;
          }
          .dashboard-body-events-section {
            margin-bottom: 50px;
          }
          .dashboard-body-events-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          .dashboard-body-events-subtitle {
            margin-bottom: 24px;
            width: 90%;
          }
          .dashboard-body-partners-section {
            margin-bottom: 50px;
          }
          .dashboard-body-quick-action-button {
            height: 48px;
            background: black;
            color: white;
            font-size: 16px;
            width: 240px;
          }
          .ant-select-item-option-selected:not(
              .ant-select-item-option-disabled
            ) {
            background: white;
          }
          .dashboard-body-link {
            text-decoration: underline;
            color: black;
          }
          .dashboard-store-link-section {
            margin-top: 24px;
            background: rgba(0, 0, 0, 0.04);
            padding: 25px 10px;
            width: 100%;
          }
          .dashboard-store-link-title {
            font-size: 18px;
            margin-bottom: 8px;
            font-weight: bold;
          }
          .dashboard-store-link-subtitle {
            width: 90%;
          }
          .dashboard-events-image {
            width: auto;
            height: 200px;
          }
          .dashboard-partners-image {
            width: auto;
            height: 200px;
          }
          .dashboard-body-partners-images-container {
            display: flex;
            gap: 30px;
          }
          .dashboard-body-quick-action-button-container {
            display: flex;
            gap: 30px 50px;
            flex-wrap: wrap;
          }
          .dashboard-body-quick-action-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 24px;
            margin-top: 8px;
          }
          .ant-input-search
            > .ant-input-group
            > .ant-input-group-addon:last-child
            .ant-input-search-button {
            background: #000;
            border: 1px solid #000;
            color: #fff;
            height: 48px;
            font-family: "DomaineSansText-Light";
            padding: 0 24px;
          }
          .ant-input-search
            .ant-input-group
            .ant-input-affix-wrapper:not(:last-child) {
            height: 48px;
            border-color: black;
          }
          .manage-orders-body-sidebar-content-select .ant-select-selector {
            margin: 0 !important;
            height: 48px !important;
            font-size: 14px !important;
            padding: 0 16px !important;
            width: 150px !important;
            border-color: black !important;
          }
          .manage-orders-body-sidebar-content-select .ant-select-arrow {
            top: 50%;
            height: auto;
            width: auto;
          }
          .manage-orders-body-sidebar-content-select.ant-select-single
            .ant-select-selector
            .ant-select-selection-item,
          .ant-select-single
            .ant-select-selector
            .ant-select-selection-placeholder {
            line-height: 3;
          }
          .seller-info-section {
            padding: 16px;
            background: white;
            margin: 24px 0;
          }
          .seller-info-payment-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 24px;
            margin-top: 8px;
          }
          .seller-info-payment-box-container {
            display: flex;
            gap: 40px;
            margin-bottom: 60px;
          }
          .seller-info-payment-box {
            background: #f7f7f7;
            padding: 16px;
            width: 300px;
            position: relative;
          }
          .seller-info-payment-box-label {
            font-size: 12px;
            margin-bottom: 8px;
          }
          .seller-info-payment-box-number {
            font-size: 24px;
            font-family: DomaineSansText-Black;
          }
          .seller-info-payment-box-extra {
            font-size: 12px;
            margin-top: 8px;
          }
          .seller-info-payment-extra {
            position: absolute;
            bottom: -40px;
            left: 0;
            text-decoration: underline;
          }
          .store-header-banner-text-header-flex-section {
            display: flex;
            gap: 8px;
            padding: 8px 0;
            align-items: center;
          }
          .store-header-banner-text-header-flex-section img {
            width: 20px;
            height: 20px;
          }
          .personal-info-status-tag {
            background: ${statusColor};
            text-transform: capitalize;
            width: fit-content;
            padding: 14px 80px;
            font-size: 12px;
            color: ${color};
            height: fit-content;
          }
          .personal-info-profile-image {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
          .personal-info-first-container {
            display: flex;
            justify-content: space-between;
            padding: 24px 0 40px;
            border-bottom: 1px solid #bfbfbf;
            margin-bottom: 40px;
          }
          .personal-info-section table {
            border-collapse: unset;
            height: fit-content;
          }
          .personal-info-section td {
            padding: 0 20px 8px 0;
            font-family: "DomaineSansText-Regular";
          }
          .personal-info-label {
            color: #707070;
            width: 175px;
          }
          .personal-info-second-container-content {
            display: flex;
            gap: 100px;
          }

          .shopBuyerModal {
            font-family: "DomaineSansText-Light";
          }
          .shopBuyerModal .ant-modal-body {
            padding: 40px 40px 60px;
            color: black;
          }
          .shop-buyer-modal-form-title {
            font-family: "DomaineSansText-Regular";
            font-size: 24px;
          }
          .btn-save {
            background: #000000;
            border-color: #000;
            color: #fff;
            height: 48px;
            width: 100%;
          }
          .btn-cancel {
            color: #000000;
            border-color: #000;
            background: #fff;
            height: 48px;
            width: 100%;
          }
          .block-select .ant-select-selector {
            height: 48px !important;
            padding: 6px 11px !important;
          }
          .ant-form-item-explain-error {
            margin-top: 4px;
          }
        `}</style>
      </div>
    </Dashboard>
  );
};

export default SellerInformation;
