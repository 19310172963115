import { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { brandActionCreators } from "../../redux/seller";
import {
  Form,
  Button,
  Input,
  Select,
  Row,
  Col,
  Upload,
  Radio,
  message,
  Tooltip,
  Checkbox,
  DatePicker,
  Space,
  Menu,
  Dropdown,
  Divider,
} from "antd";
import {
  InfoCircleOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
  PlusCircleOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import axios from "axios";
import sizes from "../../assets/sizes";
import colors from "../../assets/colors";
import PreviewLisitingModal from "./PreviewLisitingModal";
import { occasion, kidsOccasion } from "../../assets/occassions";
import weights from "../../assets/weights";
import QuantityModal from "../../components/modals/QuantityModal";

const { Option } = Select;

const EditListing = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const imgRef1 = useRef(null);
  const imgRef2 = useRef(null);
  const imgRef3 = useRef(null);
  const imgRef4 = useRef(null);
  const imgUpload1 = useRef(null);
  const imgUpload2 = useRef(null);
  const imgUpload3 = useRef(null);
  const imgUpload4 = useRef(null);

  const { state: option } = location.state;

  const [showQtyModal, setShowQtyModal] = useState(false);
  const [type, setType] = useState("");
  const [isDiscount, setDiscount] = useState(false);
  const [variant, setVariant] = useState([]);
  const [imgUrl, setImgUrl] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [categories, setCategories] = useState({});
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [productCat, setProductCat] = useState({});
  const [kidsAgeRange, setKidsAgeRange] = useState([]);
  const [kidsCategory, setKidsCategory] = useState([]);
  const [kidsOccasions, setKidsOccasions] = useState([]);
  const [kidsSubCategory, setKidsSubCategory] = useState([]);
  const [itemCat, setItemCat] = useState("");
  const [itemGroup, setItemGroup] = useState([]);
  const [itemFit, setItemFit] = useState([]);
  const [itemGender, setItemGender] = useState([]);
  const [itemSizes, setItemSizes] = useState([]);
  const [productPreview, setProductPreview] = useState({});
  const [previewModalVisible, setPreviewModalVisible] = useState(false);

  const { product } = useSelector((state) => state.brands);
  const { info } = useSelector((state) => state.user);

  const { updateProduct, swapImage, deleteImage } = bindActionCreators(
    brandActionCreators,
    dispatch
  );

  const addColor = (color) => {
    const currentColorList = selectedColors;
    if (selectedColors.length < 3) {
      const thisColorExists = currentColorList.find((e) => e.hex === color.hex);
      if (!thisColorExists) setSelectedColors(selectedColors.concat(color));
    }
  };

  const subtractColor = (color) => {
    const currentColorList = selectedColors;
    const newColorList = currentColorList.filter((e) => e.hex !== color.hex);

    setSelectedColors(newColorList);
  };

  const changeSize = (size) => {
    const sizeList = selectedSizes;

    if (size === "One Size") {
      setSelectedSizes(["One Size"]);
      return;
    }
    const hasSizeAlready = sizeList.includes(size);
    if (!hasSizeAlready) {
      const newSizeList = sizeList.filter((e) => e !== "One Size");
      setSelectedSizes(newSizeList.concat(size));
    }
    if (hasSizeAlready) setSelectedSizes(sizeList.filter((e) => e !== size));
  };

  const generateTable = () => {
    const data = [];
    if (selectedColors.length > 0 && selectedSizes.length > 0) {
      selectedColors.map((color) => {
        selectedSizes.map((size) => {
          data.push({
            key: data.length,
            itemName: form.getFieldValue("name"),
            colorObj: color,
            color: color.name,
            size,
          });
          return true;
        });
        return true;
      });
    }
    setVariant(data);
  };

  const uploadImg = async ({ file, onSuccess, onError }, ref, name) => {
    const data = new FormData();
    data.append("file", file);
    data.append("api_key", "693411225724272");
    data.append("upload_preset", "product-images");
    data.append("cloud_name", "losode-ng");
    const result = await axios.post(
      `https://api.cloudinary.com/v1_1/losode-ng/image/upload`,
      data
    );
    if (result.status === 200) {
      const url = result.data.secure_url;
      setImgUrl([...imgUrl, { [name]: url }]);
      file.status = "success";
      ref.current.style.background = `url(${result.data.secure_url})`;
      ref.current.style.backgroundSize = "cover";
      ref.current.firstChild.style.display = "none";
      ref.current.lastChild.style.display = "none";
      onSuccess("ok");
    } else onError("error");
  };

  const onFinish = async (fieldsValue) => {
    const image1 = imgUrl.find((img) => img["image1"])?.image1;
    const image2 = imgUrl.find((img) => img["image2"])?.image2;
    const image3 = imgUrl.find((img) => img["image3"])?.image3;
    const image4 = imgUrl.find((img) => img["image4"])?.image4;

    const product_catid = form.getFieldValue("product_catid");

    form.setFieldsValue({
      sub_catid: subCategory.id,
      product_catid:
        typeof product_catid === "string" ? productCat.id : product_catid,
    });

    const data = {
      ...form.getFieldsValue(),
      discount_end_date: fieldsValue["discount_end_date"]?.format("YYYY-MM-DD"),
      discount_start_date:
        fieldsValue["discount_start_date"]?.format("YYYY-MM-DD"),
      product_id: product.product_id,
    };
    data.image1 = image1 ? image1 : product.image1;
    data.image2 = image2 ? image2 : product.image2;
    data.image3 = image3 ? image3 : product.image3;
    data.image4 = image4 ? image4 : product.image4;
    data.variety = variant;
    data.seller_id = product.seller_id;
    data.currency = product.currency;

    const res = await updateProduct(info.token, data);
    if (res.status === "ok") {
      message.success(res.message);
      history.goBack();
    }
    if (res.status === "not ok") message.success(res.message);
  };

  const onPreviewLisitng = () => {
    const { image1, image2, image3, image4 } = product;
    const { discount_end_date, discount_start_date } = form.getFieldValue();
    const previewObject = {
      ...form.getFieldsValue(),
      discount_end_date: discount_end_date?.format("YYYY-MM-DD"),
      discount_start_date: discount_start_date?.format("YYYY-MM-DD"),
      images: [image1, image2, image3, image4] || imgUrl,
      variant: variant,
      // currency: pickCurrency(),
    };
    setProductPreview(previewObject);

    setPreviewModalVisible(true);
  };

  const getCategories = async () => {
    const result = await axios.get("https://losode.dev/api/v2/all/categories");
    setCategories(result.data.data);
  };

  const loadSelectedCategory = (gender) => {
    if (gender === 1) gender = "Men";
    if (gender === 2) gender = "Women";
    if (gender === 3) gender = "Kids";
    setCategory(categories[gender]?.categories);
    selectGroup(gender);
    setItemGender(gender);
  };
  const loadSubCategory = (id) => {
    const subCategory = category?.find((sub) => sub.id === Number.parseInt(id));
    setSubCategory(subCategory);
    form.setFieldsValue({ sub_catid: subCategory?.category });
    selectItemFit(subCategory?.category);
  };

  const loadProductCat = (id) => {
    const cat = subCategory?.subs?.find(
      (sub) => sub.id === Number.parseInt(id)
    );
    form.setFieldsValue({ product_catid: cat?.category });
    setProductCat(cat);
    setItemSizes(sizes(itemGender, cat?.category));
  };

  const loadSelectedKidsGender = (cat) => {
    setKidsAgeRange(category.find((item) => item.category === cat).subs);
  };

  const loadSelectedKidCategory = (range) => {
    const category = kidsAgeRange.find((item) => item.category === range).subs;
    setKidsCategory(category);
    setKidsOccasions(kidsOccasion(range));
  };

  const loadSelectedKidSubCategory = (cat) => {
    const subCategory = kidsCategory.find((item) => item.category === cat).subs;
    setKidsSubCategory(subCategory);
  };

  const loadItemSizes = (group) => {
    setItemSizes(sizes(itemGender, itemCat, group, subCategory?.category));
  };

  const selectGroup = (gender) => {
    if (gender === "Men") setItemGroup(["Plus Size", "Tall", "Regular"]);
    if (gender === "Women")
      setItemGroup(["Plus Size", "Tall", "Petite", "Maternity", "Regular"]);
  };

  const selectItemFit = (cat) => {
    if (cat === "Clothing")
      setItemFit(["Loose Fit", "Regular Fit", "Tight Fit "]);
    if (cat === "Footwear")
      setItemFit(["Wide Fit", "Regular Fit", "Slim Fit "]);
  };

  const loadImages = () => {
    const { image1, image2, image3, image4 } = product;
    let images = [image1, image2, image3, image4];
    const refs = [imgRef1, imgRef2, imgRef3, imgRef4];

    refs.map((ref, i) => {
      if (images[i] !== null && images[i] !== undefined) {
        ref.current.style.background = `url(${images[i]})`;
        ref.current.style.backgroundSize = "cover";
        ref.current.firstChild.style.display = "none";
        ref.current.lastChild.style.display = "none";
      }
      return true;
    });
  };

  const swapImg = async (ref) => {
    const res = await swapImage(info.token, {
      move_from: ref,
      move_to: "image1",
      product_id: product.product_id,
      slug: product.slug,
    });

    if (res.status === "ok") {
      message.success(res.message);
      history.push(`/listing/${product?.product_id}`);
    }
  };

  const deleteImg = async (img) => {
    const res = await deleteImage(info.token, {
      image: img,
      product_id: product.product_id,
    });

    if (res.status === "ok") {
      message.success(res.message);
      history.push(`/listing/${product?.product_id}`);
    }
  };

  const loadVariants = (item) => {
    item.variety?.map((val) => (val.itemName = item.name));
    setVariant(item.variety);
  };

  useEffect(() => {
    getCategories();
    loadImages();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    generateTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColors, selectedSizes]);

  useEffect(() => {
    const item = product;
    loadSelectedCategory(item.main_catid);
    loadVariants(item);
    setType(item.product_type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  useEffect(() => {
    const item = product;
    loadSubCategory(item.sub_catid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    const item = product;
    loadProductCat(item.product_catid);
    form.setFieldsValue({
      product_catid: item.category_name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategory]);

  useEffect(() => {
    if (subCategory?.category === "Fabrics") loadItemSizes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategory?.category]);

  const smallImageMenu = (img, ref) => (
    <Menu style={{ fontFamily: "DomaineSansText-Light", fontSize: "14px" }}>
      <Menu.Item key="0">
        <div
          style={{ display: "flex", alignItems: "center", gap: "4px" }}
          onClick={() => swapImg(img)}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 5 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.52025 0.508545L2.98759 1.45533L4.03269 1.60809L3.27647 2.34464L3.45494 3.3852L2.52025 2.89366L1.58556 3.3852L1.76403 2.34464L1.00781 1.60809L2.05291 1.45533L2.52025 0.508545Z"
              stroke="black"
              stroke-width="0.226866"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span>Set As Primary Image</span>
        </div>
      </Menu.Item>
      <Divider style={{ margin: "0" }} />
      <Menu.Item key="1">
        <div
          style={{ display: "flex", alignItems: "center", gap: "4px" }}
          onClick={() => {
            ref?.current?.upload?.uploader?.fileInput?.click();
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 5 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_12872_46376)">
              <path
                d="M0.855469 1.06982V1.97732H1.76297"
                stroke="black"
                stroke-width="0.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.23511 2.73351C1.33318 3.01187 1.51905 3.25081 1.76473 3.41434C2.01041 3.57788 2.30258 3.65713 2.59722 3.64017C2.89186 3.62322 3.17301 3.51096 3.3983 3.32032C3.6236 3.12969 3.78083 2.87099 3.84632 2.58322C3.91181 2.29545 3.88199 1.99419 3.76138 1.72484C3.64076 1.45549 3.43587 1.23263 3.17759 1.08984C2.9193 0.947057 2.6216 0.892082 2.32935 0.9332C2.0371 0.974318 1.76613 1.1093 1.55727 1.31781L0.855469 1.97726"
                stroke="black"
                stroke-width="0.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_12872_46376">
                <rect
                  width="3.63"
                  height="3.63"
                  fill="white"
                  transform="translate(0.703125 0.464844)"
                />
              </clipPath>
            </defs>
          </svg>
          <span>Change Image</span>
        </div>
      </Menu.Item>
      <Divider style={{ margin: "0" }} />
      <Menu.Item key="2">
        <div
          style={{ display: "flex", alignItems: "center", gap: "4px" }}
          onClick={() => deleteImg(img)}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 5 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_12862_46555)">
              <path
                d="M1.15625 1.74219H1.45874H3.87864"
                stroke="black"
                stroke-width="0.226866"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.57835 1.74243V3.85984C3.57835 3.94006 3.54648 4.017 3.48975 4.07373C3.43303 4.13046 3.35609 4.16233 3.27586 4.16233H1.76343C1.6832 4.16233 1.60626 4.13046 1.54953 4.07373C1.49281 4.017 1.46094 3.94006 1.46094 3.85984V1.74243M1.91467 1.74243V1.43994C1.91467 1.35971 1.94654 1.28278 2.00327 1.22605C2.05999 1.16932 2.13693 1.13745 2.21716 1.13745H2.82213C2.90236 1.13745 2.9793 1.16932 3.03602 1.22605C3.09275 1.28278 3.12462 1.35971 3.12462 1.43994V1.74243"
                stroke="black"
                stroke-width="0.226866"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.21484 2.49878V3.40624"
                stroke="black"
                stroke-width="0.226866"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.82031 2.49878V3.40624"
                stroke="black"
                stroke-width="0.226866"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_12862_46555">
                <rect
                  width="3.62985"
                  height="3.62985"
                  fill="white"
                  transform="translate(0.703125 0.835205)"
                />
              </clipPath>
            </defs>
          </svg>
          <span>Delete Image</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  const bigImageMenu = (ref, img) => (
    <Menu style={{ fontFamily: "DomaineSansText-Light", fontSize: "14px" }}>
      <Menu.Item key="1">
        <div
          style={{ display: "flex", alignItems: "center", gap: "4px" }}
          onClick={() => {
            ref?.current?.upload?.uploader?.fileInput?.click();
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 5 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_12872_46376)">
              <path
                d="M0.855469 1.06982V1.97732H1.76297"
                stroke="black"
                stroke-width="0.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.23511 2.73351C1.33318 3.01187 1.51905 3.25081 1.76473 3.41434C2.01041 3.57788 2.30258 3.65713 2.59722 3.64017C2.89186 3.62322 3.17301 3.51096 3.3983 3.32032C3.6236 3.12969 3.78083 2.87099 3.84632 2.58322C3.91181 2.29545 3.88199 1.99419 3.76138 1.72484C3.64076 1.45549 3.43587 1.23263 3.17759 1.08984C2.9193 0.947057 2.6216 0.892082 2.32935 0.9332C2.0371 0.974318 1.76613 1.1093 1.55727 1.31781L0.855469 1.97726"
                stroke="black"
                stroke-width="0.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_12872_46376">
                <rect
                  width="3.63"
                  height="3.63"
                  fill="white"
                  transform="translate(0.703125 0.464844)"
                />
              </clipPath>
            </defs>
          </svg>
          <span>Change Image</span>
        </div>
      </Menu.Item>
      <Divider style={{ margin: "0" }} />
      <Menu.Item key="2">
        <div
          style={{ display: "flex", alignItems: "center", gap: "4px" }}
          onClick={() => deleteImg(img)}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 5 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_12862_46555)">
              <path
                d="M1.15625 1.74219H1.45874H3.87864"
                stroke="black"
                stroke-width="0.226866"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.57835 1.74243V3.85984C3.57835 3.94006 3.54648 4.017 3.48975 4.07373C3.43303 4.13046 3.35609 4.16233 3.27586 4.16233H1.76343C1.6832 4.16233 1.60626 4.13046 1.54953 4.07373C1.49281 4.017 1.46094 3.94006 1.46094 3.85984V1.74243M1.91467 1.74243V1.43994C1.91467 1.35971 1.94654 1.28278 2.00327 1.22605C2.05999 1.16932 2.13693 1.13745 2.21716 1.13745H2.82213C2.90236 1.13745 2.9793 1.16932 3.03602 1.22605C3.09275 1.28278 3.12462 1.35971 3.12462 1.43994V1.74243"
                stroke="black"
                stroke-width="0.226866"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.21484 2.49878V3.40624"
                stroke="black"
                stroke-width="0.226866"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.82031 2.49878V3.40624"
                stroke="black"
                stroke-width="0.226866"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_12862_46555">
                <rect
                  width="3.62985"
                  height="3.62985"
                  fill="white"
                  transform="translate(0.703125 0.835205)"
                />
              </clipPath>
            </defs>
          </svg>
          <span>Delete Image</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  const isTabletOrMobile = false;

  return (
    <div style={{ background: "#F9F9F9" }}>
      <div className="list-navbar">
        <img
          src="/images/losode-no-tagline.png"
          alt="logo"
          style={{ width: !isTabletOrMobile ? "120px" : "95px" }}
        />
        <div
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/dashboard")}
        >
          Your Designer Dashboard
        </div>
      </div>
      <div className="account-container">
        <Form
          form={form}
          name="listing"
          initialValues={product}
          onFinish={onFinish}
          scrollToFirstError
          layout="vertical"
          requiredMark={false}
        >
          <Row
            className="form-row"
            gutter={[48, 12]}
            style={{
              background: "white",
              padding: "24px",
            }}
          >
            <div>
              <h1 className="edit-listing-welcome-txt-title">
                Edit A Listed Item
              </h1>
              <p className="edit-listing-welcome-txt-more">
                Edit your listed items here. Your listing will be resubmitted
                for approval by our Designer Acquisition Team
              </p>
            </div>

            <Col span={24}>
              <Form.Item
                name="name"
                label="Item title"
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Input
                  className="input item-title-specific"
                  placeholder="Enter a descriptive title of the item you are listing e.g Sequined drawstring silk dress by Joe Blogs"
                  suffix={
                    <Tooltip title="Ensure your title is descriptive and add your designer name at the end. If your item has multiple colours do not use colour in your title e.g., ‘Black sequined dress by Jane Bloggs’ or ‘Sequined drawstring silk dress by Joe Blogs’ ">
                      <InfoCircleOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>

            <Col span={!isTabletOrMobile ? 12 : 24}>
              <Form.Item
                name="product_type"
                label="Ready to wear or Made to order?"
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Radio.Group onChange={(e) => setType(e.target.value)}>
                  <Radio value="ready to wear">Ready to wear</Radio>
                  <Radio value="bespoke">Made to order</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            {type === "bespoke" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Space>
                  <Form.Item
                    name="bespoke_duration"
                    label= {
                      <span style={{display: 'flex', alignItems: 'center', gap: '6px'}}>
                        How long will it take for the item to be made?
                        <Tooltip title="Please note that customers are not usually prepared to wait longer than 10 days for items to be made">
                          <InfoCircleOutlined
                            style={{
                              color: "rgba(0,0,0,.45)",
                              fontSize: "14px",
                              marginRight: "6px",
                            }}
                          />
                        </Tooltip>
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select the number of days required"
                      allowClear
                      style={{
                        width: "100%",
                        marginRight: "16px",
                      }}
                      >
                      {Array.from({length: 10}, (_, i) => (
                        <Select.Option key={i + 1} value={i + 1}>
                          {i + 1}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <span
                    style={{
                      fontSize: "18px",
                      display: !isTabletOrMobile ? "block" : "none",
                    }}
                  >
                    days
                  </span>
                </Space>
              </Col>
            )}
            <Col span={!isTabletOrMobile ? 12 : 24}>
              <Form.Item
                name="product_return_type"
                label={
                  <div
                    style={{
                      display: "flex",
                      gap: "16px",
                      alignItems: "center",
                    }}
                  >
                    <div>How do you treat returns?</div>
                    <Tooltip title="As much as we do not anticipate a high number of issues. It is important to give your buyer a level of comfort. Damaged items will be returned ">
                      <InfoCircleOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                    </Tooltip>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Checkbox.Group>
                  <Checkbox value="Returns">Refunds</Checkbox>
                  <Checkbox value="Amendments">Amendments</Checkbox>
                  <Checkbox value="Exchanges">Exchanges</Checkbox>
                </Checkbox.Group>
              </Form.Item>
              {/* <div style={{ marginTop: "-16px" }}>
                Please select at least one option
              </div> */}
            </Col>

            <Col span={!isTabletOrMobile ? 12 : 24}>
              <Form.Item
                name="main_catid"
                label="Who is the item made for?"
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Select
                  className="select"
                  onChange={loadSelectedCategory}
                  placeholder="Select an option"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={2}>Women</Option>
                  <Option value={1}>Men</Option>
                  <Option value={3}>Kids</Option>
                </Select>
              </Form.Item>
            </Col>

            {itemGender === "Kids" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="kids_gender"
                  label="Tell us the gender of the child"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    onChange={loadSelectedKidsGender}
                    placeholder="Select an option"
                  >
                    {category?.map((cat) => (
                      <Option key={cat.category} value={cat.category}>
                        {cat.category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {itemGender === "Kids" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="kids_age"
                  label="Tell us the age range of the child"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    onChange={loadSelectedKidCategory}
                    placeholder="Select an option"
                  >
                    {kidsAgeRange.map((cat) => (
                      <Option key={cat.category} value={cat.category}>
                        {cat.category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {itemGender === "Kids" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="kids_cat"
                  label="What is the category"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    onChange={loadSelectedKidSubCategory}
                    placeholder="Select an option"
                  >
                    {kidsCategory.map((cat) => (
                      <Option key={cat.category} value={cat.category}>
                        {cat.category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {itemGender === "Kids" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="kids_sub_cat"
                  label="We now need to classify your item"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    // onChange={loadSelectedKidSubCategory}
                    placeholder="Select an option"
                  >
                    {kidsSubCategory.map((cat) => (
                      <Option key={cat.category} value={cat.category}>
                        {cat.category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {itemGender !== "Kids" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="sub_catid"
                  label="What is the category?"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    placeholder="Select an option"
                    onChange={loadSubCategory}
                  >
                    {category?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {itemGender !== "Kids" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="product_catid"
                  label="What is the sub-category?"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    placeholder="Select an option"
                    onSelect={(val, p) => setItemCat(p.children)}
                  >
                    {subCategory?.subs?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {itemGender === "Kids" && subCategory?.category !== "Beauty" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="occassion_type"
                  label="What occasion is the item best suited for?"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select occasion-select-box"
                    placeholder="Select an option"
                    mode="multiple"
                  >
                    {kidsOccasions?.map((item, idx) => (
                      <Option key={idx} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {itemGender !== "Kids" && subCategory?.category !== "Beauty" && (
              <Col span={!isTabletOrMobile ? 12 : 24}>
                <Form.Item
                  name="occassion_type"
                  label="What occasion is the item best suited for?"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Select
                    className="select occasion-select-box"
                    placeholder="Select an option"
                    mode="multiple"
                  >
                    {occasion.map((item, idx) => (
                      <Option key={idx} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {itemGender !== "Kids" && subCategory?.category !== "Beauty" && (
              <>
                {(subCategory?.category === "Clothing" ||
                  subCategory?.category === "Footwear") && (
                  <>
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="size_and_fit"
                        label="What is the size fit?"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Select
                          className="select"
                          placeholder="Select an option"
                        >
                          {itemFit.map((item, idx) => (
                            <Option key={idx} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="group"
                        label="What is the body fit?"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Select
                          className="select"
                          placeholder="Select an option"
                          onChange={loadItemSizes}
                        >
                          {itemGroup.map((group, idx) => (
                            <Option key={idx} value={group}>
                              {group}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </>
            )}

            <Col span={24}>
              <Form.Item
                name="description"
                label="What is the description of the item? "
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Input.TextArea
                  style={{ minHeight: "100px" }}
                  className="input"
                  placeholder="e.g., Round neck black sequined dress that can be worn for a night out"
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="material_info"
                label="How should buyers care for the item? "
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Input.TextArea
                  style={{ minHeight: "100px" }}
                  className="input"
                  placeholder="Add useful information on material composition and how to clean the item> e.g., 100% cotton, hand wash only"
                />
              </Form.Item>
            </Col>

            <Col span={!isTabletOrMobile ? 12 : 24}>
              <Form.Item
                name="price"
                label="How much is your item? "
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Input
                  className="input"
                  placeholder="Enter price"
                  type="number"
                  suffix={
                    <Tooltip title="Prices must be set in your local currency. If you want to set prices in a different currency, change your base location in Store settings">
                      <InfoCircleOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                          fontSize: "20px",
                          marginRight: "20px",
                        }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>

            {(subCategory?.category === "Clothing" ||
              subCategory?.category === "Footwear" ||
              subCategory?.category === "Fabrics") && (
              <>
                <Col span={24}>
                  <Form.Item label="Tell us about the colour of your items (Select a maximum of three (3) colours) ">
                    {selectedColors.length > 0 && (
                      <div className="selected-colors-text-container">
                        <div>
                          Create a new listing if you have more than three (3)
                          colours)
                        </div>
                        <div className="selected-colors-container">
                          {selectedColors.map((color, idx) => {
                            return (
                              <div
                                className="selected-color-container"
                                key={idx}
                              >
                                <div
                                  className="color-check-container"
                                  style={{
                                    borderColor:
                                      color.hex !== "#000000"
                                        ? color.hex
                                        : "white",
                                    backgroundColor: "black",
                                  }}
                                >
                                  <CheckCircleFilled
                                    style={{
                                      color:
                                        color.hex !== "#000000"
                                          ? color.hex
                                          : "#383838",
                                      backgroundColor: "white",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </div>
                                <div className="color-name">{color.name}</div>
                                <CloseCircleOutlined
                                  onClick={() => subtractColor(color)}
                                  style={{ fontSize: "16px" }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    <div className="all-colors-container">
                      {colors.map((color, idx) => {
                        return (
                          <div
                            onClick={() => addColor(color)}
                            className="color-container"
                            key={idx}
                          >
                            <div
                              style={{ background: color.hex }}
                              className="color-hex-box"
                            ></div>
                            <div className="color-name">{color.name}</div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="color-container-extra">
                      Note: You have told us that the best category for your
                      item is ‘beauty’, we have assumed that size and colour are
                      of no relevance to you. Please add the item volume to your
                      title e.g., ‘Shea butter by Losode – 500ml’ or Lip colour
                      by ‘Losode – rose pink’
                    </div>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={
                      <div>
                        Please select all sizes your item is available in. You
                        can select multiple sizes
                      </div>
                    }
                  >
                    <div className="sizes-section-container">
                      <div className="sizes-container">
                        {itemSizes?.map((size, idx) => {
                          return (
                            <div
                              className="size-contain"
                              onClick={() => changeSize(size.letter)}
                              key={idx}
                              style={{
                                borderColor: selectedSizes.includes(size.letter)
                                  ? "black"
                                  : "#D4D4D4",
                                borderWidth: selectedSizes.includes(size.letter)
                                  ? "2px"
                                  : "1px",
                              }}
                            >
                              <div
                                className="size-letter"
                                style={{
                                  backgroundColor: selectedSizes.includes(
                                    size.letter
                                  )
                                    ? "black"
                                    : "white",
                                  color: selectedSizes.includes(size.letter)
                                    ? "white"
                                    : "black",
                                }}
                              >
                                {size.letter}
                              </div>
                              {/* <div
                              className="size-number"
                              style={{
                                backgroundColor: selectedSizes.includes(
                                  size.letter
                                )
                                  ? "black"
                                  : "white",
                                color: selectedSizes.includes(size.letter)
                                  ? "white"
                                  : "black",
                              }}
                            >
                              {size.number}
                            </div> */}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="one-size-extra">
                      View our standard{" "}
                      <strong style={{ textDecoration: "underline" }}>
                        size guide
                      </strong>
                      . If you have your own size guide, add it in store
                      settings
                    </div>
                  </Form.Item>
                </Col>
              </>
            )}

            <Col span={24}>
              <Form.Item label="Upload Product Image (s)">
                <div className="product-image-section">
                  <div
                    className="product-image-contain-dropdown"
                    style={{ position: "relative" }}
                  >
                    <ImgCrop aspect={11 / 16}>
                      <Upload
                        customRequest={(file) =>
                          uploadImg(file, imgRef1, "image1")
                        }
                        showUploadList={false}
                        ref={imgUpload1}
                      >
                        <div className="product-big-image" ref={imgRef1}>
                          <PlusCircleOutlined className="product-image-upload-icon-big" />
                          <div className="product-image-upload-text-big">
                            Add Main Image
                          </div>
                        </div>
                      </Upload>
                    </ImgCrop>
                    <Dropdown
                      overlay={() => bigImageMenu(imgUpload1, "image1")}
                      trigger={["hover"]}
                      placement="bottomRight"
                    >
                      <div
                        className="dropdown-icon"
                        style={{ position: "absolute" }}
                      >
                        <svg
                          width="50"
                          height="50"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="7.5"
                            cy="8.5"
                            r="7.5"
                            fill="white"
                            fill-opacity="0.7"
                          />
                          <path
                            d="M7.83201 9.44037C8.2268 9.44037 8.54683 9.11526 8.54683 8.71421C8.54683 8.31315 8.2268 7.98804 7.83201 7.98804C7.43722 7.98804 7.11719 8.31315 7.11719 8.71421C7.11719 9.11526 7.43722 9.44037 7.83201 9.44037Z"
                            stroke="black"
                            stroke-width="0.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.9375 9.44037C12.3323 9.44037 12.6523 9.11526 12.6523 8.71421C12.6523 8.31315 12.3323 7.98804 11.9375 7.98804C11.5427 7.98804 11.2227 8.31315 11.2227 8.71421C11.2227 9.11526 11.5427 9.44037 11.9375 9.44037Z"
                            stroke="black"
                            stroke-width="0.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M3.71873 9.44037C4.11351 9.44037 4.43355 9.11526 4.43355 8.71421C4.43355 8.31315 4.11351 7.98804 3.71873 7.98804C3.32394 7.98804 3.00391 8.31315 3.00391 8.71421C3.00391 9.11526 3.32394 9.44037 3.71873 9.44037Z"
                            stroke="black"
                            stroke-width="0.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </Dropdown>
                  </div>

                  <div className="product-small-images-container">
                    <div
                      className="product-image-contain-dropdown"
                      style={{ position: "relative" }}
                    >
                      <ImgCrop aspect={11 / 16}>
                        <Upload
                          customRequest={(file) =>
                            uploadImg(file, imgRef2, "image2")
                          }
                          showUploadList={false}
                          ref={imgUpload2}
                        >
                          <div className="product-small-image" ref={imgRef2}>
                            <PlusCircleOutlined className="product-image-upload-icon-small" />
                            <div className="product-image-upload-text-small">
                              Back View
                            </div>
                          </div>
                        </Upload>
                      </ImgCrop>
                      {imgRef2?.current?.style?.background && (
                        <Dropdown
                          overlay={() => smallImageMenu("image2", imgUpload2)}
                          trigger={["hover"]}
                        >
                          <div
                            className="dropdown-icon"
                            style={{ position: "absolute" }}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="7.5"
                                cy="8.5"
                                r="7.5"
                                fill="white"
                                fill-opacity="0.7"
                              />
                              <path
                                d="M7.83201 9.44037C8.2268 9.44037 8.54683 9.11526 8.54683 8.71421C8.54683 8.31315 8.2268 7.98804 7.83201 7.98804C7.43722 7.98804 7.11719 8.31315 7.11719 8.71421C7.11719 9.11526 7.43722 9.44037 7.83201 9.44037Z"
                                stroke="black"
                                stroke-width="0.6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.9375 9.44037C12.3323 9.44037 12.6523 9.11526 12.6523 8.71421C12.6523 8.31315 12.3323 7.98804 11.9375 7.98804C11.5427 7.98804 11.2227 8.31315 11.2227 8.71421C11.2227 9.11526 11.5427 9.44037 11.9375 9.44037Z"
                                stroke="black"
                                stroke-width="0.6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M3.71873 9.44037C4.11351 9.44037 4.43355 9.11526 4.43355 8.71421C4.43355 8.31315 4.11351 7.98804 3.71873 7.98804C3.32394 7.98804 3.00391 8.31315 3.00391 8.71421C3.00391 9.11526 3.32394 9.44037 3.71873 9.44037Z"
                                stroke="black"
                                stroke-width="0.6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </Dropdown>
                      )}
                    </div>
                    <div
                      className="product-image-contain-dropdown"
                      style={{ position: "relative" }}
                    >
                      <ImgCrop aspect={11 / 16}>
                        <Upload
                          customRequest={(file) =>
                            uploadImg(file, imgRef3, "image3")
                          }
                          showUploadList={false}
                          ref={imgUpload3}
                        >
                          <div className="product-small-image" ref={imgRef3}>
                            <PlusCircleOutlined className="product-image-upload-icon-small" />
                            <div className="product-image-upload-text-small">
                              Side View
                            </div>
                          </div>
                        </Upload>
                      </ImgCrop>
                      {imgRef3?.current?.style?.background && (
                        <Dropdown
                          overlay={() => smallImageMenu("image3", imgUpload3)}
                          trigger={["hover"]}
                        >
                          <div
                            className="dropdown-icon"
                            style={{ position: "absolute" }}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="7.5"
                                cy="8.5"
                                r="7.5"
                                fill="white"
                                fill-opacity="0.7"
                              />
                              <path
                                d="M7.83201 9.44037C8.2268 9.44037 8.54683 9.11526 8.54683 8.71421C8.54683 8.31315 8.2268 7.98804 7.83201 7.98804C7.43722 7.98804 7.11719 8.31315 7.11719 8.71421C7.11719 9.11526 7.43722 9.44037 7.83201 9.44037Z"
                                stroke="black"
                                stroke-width="0.6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.9375 9.44037C12.3323 9.44037 12.6523 9.11526 12.6523 8.71421C12.6523 8.31315 12.3323 7.98804 11.9375 7.98804C11.5427 7.98804 11.2227 8.31315 11.2227 8.71421C11.2227 9.11526 11.5427 9.44037 11.9375 9.44037Z"
                                stroke="black"
                                stroke-width="0.6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M3.71873 9.44037C4.11351 9.44037 4.43355 9.11526 4.43355 8.71421C4.43355 8.31315 4.11351 7.98804 3.71873 7.98804C3.32394 7.98804 3.00391 8.31315 3.00391 8.71421C3.00391 9.11526 3.32394 9.44037 3.71873 9.44037Z"
                                stroke="black"
                                stroke-width="0.6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </Dropdown>
                      )}
                    </div>
                    <div
                      className="product-image-contain-dropdown"
                      style={{ position: "relative" }}
                    >
                      <ImgCrop aspect={11 / 16}>
                        <Upload
                          customRequest={(file) =>
                            uploadImg(file, imgRef4, "image4")
                          }
                          showUploadList={false}
                          ref={imgUpload4}
                        >
                          <div className="product-small-image" ref={imgRef4}>
                            <PlusCircleOutlined className="product-image-upload-icon-small" />
                            <div className="product-image-upload-text-small">
                              Item Detail
                            </div>
                          </div>
                        </Upload>
                      </ImgCrop>
                      {imgRef4?.current?.style?.background && (
                        <Dropdown
                          overlay={() => smallImageMenu("image4", imgUpload4)}
                          trigger={["hover"]}
                        >
                          <div
                            className="dropdown-icon"
                            style={{ position: "absolute" }}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="7.5"
                                cy="8.5"
                                r="7.5"
                                fill="white"
                                fill-opacity="0.7"
                              />
                              <path
                                d="M7.83201 9.44037C8.2268 9.44037 8.54683 9.11526 8.54683 8.71421C8.54683 8.31315 8.2268 7.98804 7.83201 7.98804C7.43722 7.98804 7.11719 8.31315 7.11719 8.71421C7.11719 9.11526 7.43722 9.44037 7.83201 9.44037Z"
                                stroke="black"
                                stroke-width="0.6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.9375 9.44037C12.3323 9.44037 12.6523 9.11526 12.6523 8.71421C12.6523 8.31315 12.3323 7.98804 11.9375 7.98804C11.5427 7.98804 11.2227 8.31315 11.2227 8.71421C11.2227 9.11526 11.5427 9.44037 11.9375 9.44037Z"
                                stroke="black"
                                stroke-width="0.6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M3.71873 9.44037C4.11351 9.44037 4.43355 9.11526 4.43355 8.71421C4.43355 8.31315 4.11351 7.98804 3.71873 7.98804C3.32394 7.98804 3.00391 8.31315 3.00391 8.71421C3.00391 9.11526 3.32394 9.44037 3.71873 9.44037Z"
                                stroke="black"
                                stroke-width="0.6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </Dropdown>
                      )}
                    </div>
                  </div>
                </div>
                <div className="product-image-section-extra">
                  Use clear images taken with good lighting, you can add up to
                  four (4), we suggest that you add at least two (2) images to
                  make ‘buyers’ more comfortable with the item
                </div>
              </Form.Item>
            </Col>
          </Row>

          <Row
            className="form-row"
            gutter={[48, 12]}
            style={{
              background: "white",
              padding: "24px",
            }}
          >
            <div
              style={{
                padding: "24px",
                background: "white",
                width: "100%",
              }}
            >
              <h4>
                If you want to update your Item color size or quantity, kindly
                click on the button below.
              </h4>

              <Button
                className="btn-quantity-modal"
                type="primary"
                onClick={() => setShowQtyModal(true)}
              >
                Update Quantity
              </Button>
            </div>
          </Row>

          <Row
            className="form-row"
            gutter={[48, 12]}
            style={{
              background: "white",
              padding: "24px",
            }}
          >
            <Col span={24}>
              <Form.Item
                name="has_discount"
                label={
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <div>Would you like to add a discount to your item? </div>
                    <Tooltip title="Giving your buyers a discount when you first open your store is a good idea and can drive a high volume of sales quickly ">
                      <InfoCircleOutlined
                        style={{
                          color: "rgba(0,0,0,.45)",
                          fontSize: "16px",
                          marginRight: "20px",
                        }}
                      />
                    </Tooltip>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => setDiscount(e.target.value)}
                  value={isDiscount}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {(form.getFieldValue("has_discount") || isDiscount) && (
              <>
                <Col span={!isTabletOrMobile ? 6 : 24}>
                  <Form.Item
                    name="discount_percent"
                    label="Enter discount percentage"
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "11px",
                      }}
                    >
                      <Input
                        type="number"
                        className="input"
                        style={{ width: "40%" }}
                      />
                      <div style={{ fontSize: "20px" }}>%</div>
                    </div>
                    {/* <div
                      className="product-image-section-extra"
                      style={{ marginTop: "16px" }}
                    >
                      Field should be numeric only
                    </div> */}
                  </Form.Item>
                </Col>
                <Col span={!isTabletOrMobile ? 9 : 24}>
                  <Form.Item
                    name="discount_start_date"
                    label="When do you want the discount to start?"
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <DatePicker
                      className="date"
                      placeholder="Select start date"
                    />
                  </Form.Item>
                  <Form.Item
                    name="discount_start_type"
                    style={{ marginTop: "-16px" }}
                  >
                    <Checkbox value="immediately">
                      <span style={{ marginRight: "4px" }}>
                        Start immediately{" "}
                      </span>
                      <Tooltip title="The discount you have applied will start as soon as your listing is live">
                        <InfoCircleOutlined
                          style={{
                            color: "rgba(0,0,0,.45)",
                            fontSize: "20px",
                            marginRight: "20px",
                          }}
                        />
                      </Tooltip>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={!isTabletOrMobile ? 9 : 24}>
                  <Form.Item
                    name="discount_end_date"
                    label="When do you want the discount to end?"
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                  >
                    <DatePicker
                      className="date"
                      placeholder="Select end date"
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            {/* </div> */}
          </Row>
          <Row className="form-row" gutter={[48, 12]}>
            <div
              style={{
                padding: !isTabletOrMobile ? "24px 24px 64px" : "16px",
                background: "white",
                width: "100%",
              }}
            >
              <Col span={24}>
                <div className="delivery-title">Delivery</div>
                <div className="delivery-box">
                  <div className="delivery-notification">
                    <InfoCircleOutlined
                      style={{
                        color: "rgba(0,0,0,.45)",
                        fontSize: "16px",
                        marginRight: "20px",
                      }}
                    />
                    We work with third-party delivery providers to handle your
                    delivery
                  </div>

                  {/* <Form.Item
                    name="delivery_type"
                    label={
                      <div>
                        <strong>How will this item be delivered?</strong>
                      </div>
                    }
                    rules={[
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                    style={{
                      width: !isTabletOrMobile ? "60%" : "100%",
                      marginBottom: "0px",
                    }}
                  >
                    
                    <Radio.Group
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "16px",
                      }}
                      onChange={(e) => setSelfDeliveryInfo(e.target.value)}
                    >
                      <Radio value="self">Handle delivery yourself?</Radio>
                      <Radio value="losode">Losode handles delivery?</Radio>
                      <Radio value="pay">
                        You pay for delivery (free to the buyer)?
                      </Radio>
                    </Radio.Group>
                  </Form.Item> */}
                  <div
                    style={{
                      width: !isTabletOrMobile ? "40%" : "100%",
                      marginBottom: "0px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Form.Item
                        name="product_weight"
                        label={
                          <div
                            style={{
                              display: "flex",
                              gap: "8px",
                              alignItems: "center",
                            }}
                          >
                            <strong>Tell us the weight of your item</strong>
                            <Tooltip title="Ensure you have entered the correct weight to avoid additional charges">
                              <InfoCircleOutlined
                                style={{
                                  color: "rgba(0,0,0,.45)",
                                  fontSize: "16px",
                                  marginRight: "20px",
                                }}
                              />
                            </Tooltip>
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Select
                          className="select occasion-select-box"
                          placeholder="Select an option"
                          style={{ width: "80%" }}
                        >
                          {weights(
                            subCategory?.category,
                            subCategory?.slug
                          )?.map((item) => (
                            <Option key={item.id} value={item.KG}>
                              {item.KG}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <span> Kg </span>
                    </div>

                    <div className="weight-extra-text">
                      Please provide accurate weights for your item to avoid
                      losing a sale. Delivery fees are automatically calculated
                      based on the total weight of all items in a buyer’s
                      basket. Inaccurate weights may result in high delivery
                      estimates and may cause buyers to abandon their basket.
                    </div>
                  </div>
                </div>
              </Col>
            </div>

            {option === "listing" && (
              <div
                style={{
                  padding: !isTabletOrMobile ? "0" : "16px",
                  width: "100%",
                  display: !isTabletOrMobile ? "flex" : "block",
                }}
              >
                <Col span={!isTabletOrMobile ? 8 : 24}>
                  <Form.Item>
                    <Button
                      className="edit-listing-welcome-btn-submit"
                      htmlType="submit"
                      icon={<ArrowRightOutlined />}
                    >
                      Update listing
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={!isTabletOrMobile ? 8 : 24}>
                  <Form.Item>
                    <Button
                      className="edit-listing-welcome-btn-preview"
                      onClick={() => onPreviewLisitng()}
                      icon={<ArrowRightOutlined />}
                    >
                      Preview
                    </Button>
                  </Form.Item>
                </Col>
              </div>
            )}

            <Col span={24}>
              <div className="list-item-submit-terms">
                By selecting submit for listing, you agree to the Losode Seller
                agreement and acknowledge reading our Privacy Policy. You assume
                full responsibility for the item offered and the content of your
                listing
              </div>
            </Col>
          </Row>
        </Form>
      </div>

      <QuantityModal
        visible={showQtyModal}
        setVisible={setShowQtyModal}
        product={product}
      />

      <PreviewLisitingModal
        product={productPreview}
        modalVisible={previewModalVisible}
        setModalVisible={setPreviewModalVisible}
      />

      <style jsx="true">{`
        .dropdown-icon {
          display: none;
          top: 5%;
          right: 5%;
        }
        .product-image-contain-dropdown:hover .dropdown-icon {
          display: block;
        }
        .ant-modal-footer > button:nth-child(2) {
          background: black;
          color: white;
          height: 47px;
          padding: 0 30px;
          font-size: 16px;
          border: 4px;
          font-family: "DomaineSansText-Light";
        }
        .ant-modal-footer > button:nth-child(1) {
          background: white;
          color: black;
          height: 47px;
          padding: 0 30px;
          font-size: 16px;
          border: 4px;
          font-family: "DomaineSansText-Light";
        }
        .list-navbar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 20px;
          font-family: "DomaineSansText-Light";
          padding: 20px 40px;
          background: white;
        }
        .all-colors-container {
          display: flex;
          flex-wrap: wrap;
          gap: 11px;
          border: 1px solid black;
          padding: 11px;
          justify-content: flex-start;
          height: 135px;
          font-family: "DomaineSansText-Light";
          overflow: auto;
        }
        .selected-colors-text-container {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          gap: 11px;
          border: 1px solid black;
          padding: 11px 15px;
          border-bottom: 0;
          font-family: "DomaineSansText-Light";
        }
        .selected-colors-container {
          display: flex;
          gap: 16px;
          font-family: "DomaineSansText-Light";
        }
        .color-container {
          padding: 8px;
          border: 1px solid black;
          width: 120px;
          display: flex;
          align-items: center;
          gap: 10px;
          justify-content: center;
          white-space: nowrap;
          cursor: pointer;
          margin: 0 auto;
        }
        .selected-color-container {
          padding: 8px;
          border: 1px solid black;
          width: 145px;
          display: flex;
          align-items: center;
          gap: 16px;
          justify-content: center;
          white-space: nowrap;
          background: black;
          color: white;
        }
        .color-hex-box {
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }
        .color-check-container {
          border: 1px solid;
          border-radius: 50%;
          height: 18px;
          width: 18px;
          text-align: center;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .color-container-extra {
          font-size: 13px;
          margin-top: 8px;
        }

        .sizes-section-container {
          display: flex;
          gap: 11px;
          width: 70%;
        }
        .sizes-container {
          display: flex;
          flex-wrap: wrap;
          gap: 11px;
        }
        .size-contain {
          border: 1px solid black;
          padding: 10px;
          display: flex;
          flex-direction: column;
          gap: 11px;
          align-items: center;
          cursor: pointer;
        }
        .one-size {
          border: 1px solid black;
          width: 90px;
          text-align: center;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .size-letter,
        .size-number {
          border: 1px solid black;
          text-align: center;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
        }
        .one-size-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .one-size-extra {
          font-size: 13px;
          margin-top: 11px;
        }

        .product-image-section {
          display: flex;
          gap: 16px;
          padding: 16px;
          border: 1px solid black;
          width: fit-content;
        }
        .product-big-image {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 1.5px dashed;
          background-color: #fff5f8;
          width: 300px;
          height: 300px;
        }
        .product-small-image {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 1.5px dashed;
          background-color: #fff5f8;

          width: 92px;
          height: 92px;
          text-align: center;
        }
        .product-small-images-container {
          display: flex;
          flex-direction: column;
          gap: 8px;
          justify-content: space-between;
        }
        .product-image-upload-icon-big {
          font-size: 30px;
          margin-bottom: 16px;
        }
        .product-image-upload-icon-small {
          font-size: 15px;
          margin-bottom: 8px;
        }
        .product-image-upload-text-small {
          font-size: 10px;
        }
        .product-image-section-extra {
          font-size: 13px;
          margin-top: 8px;
        }
        .table-quantity-container {
          display: flex;
          align-items: center;
          gap: 20px;
          width: 200px;
        }
        .table-quantity-delete-icon {
          cursor: pointer;
        }

        textarea.ant-input {
          padding-top: 16px;
          padding-left: 16px;
        }

        .weight-extra-text {
          background: black;
          color: white;
          padding: 11px;
          margin-top: 30px;
        }
        .weight-number-box {
          display: flex;
          gap: 20px;
          align-items: center;
          border: 1px solid black;
          width: fit-content;
          padding: 11px;
        }
        .weight-number-section {
          display: flex;
          gap: 11px;
          align-items: center;
        }
        .delivery-box {
          display: flex;
          border: 1px solid rgb(111 111 111 / 29%);
          padding: 25px;
          justify-content: space-between;
          gap: 70px;
        }
        .delivery-title {
          font-size: 24px;
          margin-bottom: 16px;
          font-weight: bold;
        }
        .edit-listing-welcome-btn-preview,
        .edit-listing-welcome-btn-later {
          background: white;
          font-size: 18px;
          height: 56px;
          width: 274px;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          display: inline-flex;
        }
        .edit-listing-welcome-btn-preview,
        .edit-listing-welcome-btn-later,
        .edit-listing-welcome-btn-submit {
          margin-top: 32px;
        }
        .help-banner {
          position: fixed;
          background: black;
          color: white;
          padding: 10px;
          top: 70%;
          right: 0;
          cursor: pointer;
        }

        .account-container {
          font-family: DomaineSansText-Light;
          padding: 3% 10%;
        }
        .edit-listing-welcome-txt-title {
          font: normal 600 24px/42px DomaineSansText-Regular;
          color: #000000;
          margin: 0;
          padding: 0 24px;
        }
        .edit-listing-welcome-txt-more {
          font: normal normal 13px DomaineSansText-Light;
          color: black;
          padding: 0 24px;
          margin-bottom: 24px;
        }

        .edit-listing-welcome-txt-about {
          font: normal 600 24px/42px DomaineSansText-Regular;
          color: #000000;
          margin: 0;
          text-align: center;
        }
        .edit-listing-welcome-txt-info {
          font: normal normal 14px DomaineSansText-Light;
          color: black;
          width: 70%;
          text-align: center;
          margin: 8px auto;
        }

        .ant-form-vertical .ant-form-item-label > label {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
        }
        .ant-form-item-explain.ant-form-item-explain-error {
          margin-top: 8px;
        }
        .form-row {
          margin-top: 26.52px;
        }
        .input {
          height: 56px;
          border: 1px solid black;
          padding: 4px 0px 4px 14px;
          font-size: 14px;
          font-family: "DomaineSansText-Light";
        }
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector,
        .ant-picker,
        .ant-select-selector {
          height: 56px !important;
          width: 100%;
          border: 1px solid black;
        }
        .ant-picker-input > input,
        .ant-checkbox-group {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
        }
        .ant-select,
        .ant-input-affix-wrapper > input.ant-input,
        span.ant-radio + * {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
        }
        .ant-radio-group {
          display: flex;
          align-items: center;
        }
        .ant-radio-inner {
          width: 20px;
          height: 20px;
        }
        .ant-radio-wrapper {
          margin-right: 40px;
        }
        .ant-input-affix-wrapper > input.ant-input {
          color: #000;
        }
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          border: 1px solid black;
          height: 56px;
          padding: 0px;
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          display: flex;
          padding: 14px;
        }
        .btn-upload,
        .btn-upload:hover {
          background: #800000;
          color: #fff;
          height: 56px;
          width: 151px;
        }
        .edit-listing-welcome-btn-submit {
          font-size: 18px;
          height: 56px;
          background: black;
          width: 274px;
          color: white;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          display: inline-flex;
        }
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          padding: 13px;
        }
        .ant-tooltip-inner {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
          background: #f4f4f4;
          color: black;
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          background-color: #e5e5e5;
        }
        .ant-popover-inner-content {
          font-family: "DomaineSansText-Light";
        }
        .item-title-specific input.ant-input {
          text-transform: capitalize;
        }

        .btn-quantity-modal {
          font-size: 16px;
          margin-top: 35px;
          width: 265px;
          height: 50px;
        }

        @media (max-width: 640px) {
          .account-container {
            padding: 0;
          }
          .txt-title,
          .txt-about {
            font-size: 22px !important;
            font-weight: 700;
            text-align: center;
            width: 100%;
            line-height: 15px;
            font-family: DomaineSansText-Regular !important;
          }
          .txt-more,
          .txt-info {
            font-size: 10px;
            text-align: center;
            width: 100%;
            line-height: 15px;
          }
          .form-row {
            row-gap: 7px !important;
            margin-top: 5px !important;
          }
          .btn-upload {
            height: 40px;
            width: 86px;
            margin: 3px;
            padding: 5px;
          }
          .btn-upload span {
            line-height: 1.2;
          }
          .btn-upload img {
            height: 16px;
          }
          .btn-submit {
            width: 100%;
            margin-bottom: 130px;
            margin-top: 16px;
            font-size: 14px;
            font-family: DomaineSansText-Light;
          }
          .list-navbar {
            font-size: 16px;
            padding: 16px;
          }
          .input,
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector,
          .ant-picker,
          .ant-select-selector {
            height: 48px !important;
            margin-top: 0 !important;
            font-size: 13px !important;
          }
          .ant-select,
          .ant-input-affix-wrapper > input.ant-input,
          span.ant-radio + * {
            font-size: 13px !important;
          }
          .ant-form-item-explain-error {
            margin-top: 8px;
            font-size: 12px;
            margin-bottom: 8px;
          }
          .ant-form-vertical .ant-form-item-label > label,
          .ant-form label,
          .ant-input:placeholder-shown,
          .ant-picker-input > input:placeholder-shown {
            font-size: 13px !important;
          }
          .product-image-section {
            flex-direction: column;
          }
          .product-image-section .ant-upload,
          .product-image-section span,
          .product-big-image {
            width: 100%;
          }
          .product-small-images-container {
            flex-direction: row;
          }
          .delivery-box {
            gap: 24px;
            flex-direction: column;
            padding: 0;
            border: 0;
          }
          .delivery-title,
          .edit-listing-welcome-txt-title {
            font-size: 18px;
          }
          .weight-extra-text {
            font-size: 13px;
          }
          .edit-listing-welcome-btn-preview,
          .edit-listing-welcome-btn-later,
          .edit-listing-welcome-btn-submit {
            width: 100%;
            justify-content: center;
            gap: 16px;
            font-size: 16px;
          }
          .edit-listing-welcome-btn-preview,
          .edit-listing-welcome-btn-later,
          .edit-listing-welcome-btn-submit {
            margin-top: 0px;
          }
          .list-item-submit-terms {
            padding: 0 16px;
            font-size: 13px;
            margin-bottom: 24px;
          }
          .ant-select-arrow {
            top: 50%;
          }
          .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
          .ant-select-single.ant-select-show-arrow
            .ant-select-selection-placeholder {
            line-height: 1.6;
          }
          .color-container {
            font-size: 13px;
            width: 48%;
          }
          .selected-color-container {
            width: 47%;
            font-size: 13px;
            height: fit-content;
          }
          .selected-colors-container {
            gap: 11px;
            flex-wrap: wrap;
          }
          .selected-colors-text-container-title {
            font-size: 13px;
          }
          .size-contain {
            width: 31%;
          }
          .size-letter,
          .size-number {
            font-size: 13px;
            height: fit-content;
            padding: 6px;
          }
          .sizes-section-container {
            width: 100%;
          }
          .table-quantity-container {
            width: auto;
          }
          .occasion-select-box .ant-select-selector {
            height: fit-content !important;
            padding: 4px;
          }
        }
      `}</style>
    </div>
  );
};

export default EditListing;
