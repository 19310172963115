import { useEffect, useState } from "react";
import { Button } from "antd";
// import { useNavigate } from "react-router-dom";
// import { bindActionCreators } from "redux";
import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import OrderView from "./OrderView";
import { useHistory } from "react-router-dom";
import Dashboard from "../seller-mgmt/Dashboard";
import { getLatestOrders } from "../../api/ordersApi";
import { getAdminPendingInfo, getAdminStats } from "../../api/brandsApi";
import { months } from "../../assets/constants";

const SellerDashboard = () => {
  const isTabletOrMobile = false;

  const history = useHistory();

  const [newOrders, setNewOrders] = useState([]);
  const [stats, setStats] = useState({});
  const [pending, setPending] = useState({});

  const { info } = useSelector((state) => state.user);

  const getNewOrders = async () =>
    setNewOrders(await getLatestOrders(info.token));

  const getStats = async () => setStats(await getAdminStats(info.token));
  const getPendingInfo = async () =>
    setPending(await getAdminPendingInfo(info.token));

  const currentMonth = months[new Date().getMonth()];

  const { order, listing, photoshoot } = stats?.numbers || {};

  useEffect(() => {
    getNewOrders();
    getStats();
    getPendingInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("GEETTTTTTTTTTTtt", stats);

  return (
    <Dashboard
      headerTitle="The Designer Management Portal"
      // //headerSubtitle="Get inisights on all users using your app"
      currentPageKey="1"
    >
      <div className="dashboard-container">
        <div className="manage-listing-all-content">
          <div className="dashboard-coverall-container">
            {/* <div className="dashboard-adspace"></div> */}

            <div className="dashboard-body-main">
              <div className="dashboard-body-container">
                <div className="dashboard-body-content">
                  <div
                    className="dashboard-body-bold-numbers-container-contain"
                    style={{
                      marginBottom: "50px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                      marginTop: "24px",
                    }}
                  >
                    <div className="dashboard-body-bold-numbers-container">
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-earned">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {stats?.newSellers}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          NEW DESIGNERS IN {currentMonth}
                        </div>
                      </div>
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-listed">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {Number(stats?.numbers?.designer?.publishedStores) +
                            Number(
                              stats?.numbers?.designer?.unpublishedStores
                            ) +
                            Number(stats?.numbers?.designer?.storesOnVacation)}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          DESIGNERS ON LOSODE
                        </div>
                      </div>
                      {/* <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-sold">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {stats?.monthlyGrowth}
                          <span>
                            <svg
                              style={{ verticalAlign: "middle" }}
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 19V5"
                                stroke="#95C645"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M5 12L12 5L19 12"
                                stroke="#95C645"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          MONTHLY GROWTH RATE
                        </div>
                      </div> */}
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-listed">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {stats?.numbers?.designer?.publishedStores}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          PUBLISHED STORES
                        </div>
                      </div>
                    </div>
                    <div className="dashboard-body-bold-numbers-container">
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-earned">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {order?.new}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          NEW ORDERS IN {currentMonth}
                        </div>
                      </div>
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-listed">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {order?.total}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          ORDERS ON LOSODE
                        </div>
                      </div>
                      {/* <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-sold">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {order?.growthRate}
                          <span>
                            <svg
                              style={{ verticalAlign: "middle" }}
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 19V5"
                                stroke="#95C645"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M5 12L12 5L19 12"
                                stroke="#95C645"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          MONTHLY GROWTH RATE
                        </div>
                      </div> */}
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-listed">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {stats?.numbers?.designer?.unpublishedStores}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          UNPUBLISHED STORES
                        </div>
                      </div>
                    </div>
                    <div className="dashboard-body-bold-numbers-container">
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-earned">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {photoshoot?.new}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          NEW PHOTOSHOOTS IN {currentMonth}
                        </div>
                      </div>
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-listed">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {photoshoot?.total}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          PHOTOSHOOTS ON LOSODE
                        </div>
                      </div>
                      {/* <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-sold">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {photoshoot?.growthRate}
                          <span>
                            <svg
                              style={{ verticalAlign: "middle" }}
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 19V5"
                                stroke="#95C645"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M5 12L12 5L19 12"
                                stroke="#95C645"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          MONTHLY GROWTH RATE
                        </div>
                      </div> */}
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-listed">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {stats?.numbers?.designer?.storesOnVacation}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          STORES ON VACATION
                        </div>
                      </div>
                    </div>
                    <div className="dashboard-body-bold-numbers-container">
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-earned">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {listing?.new}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          NEW LISTINGS IN {currentMonth}
                        </div>
                      </div>
                      <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-listed">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {listing?.total}
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          LISTINGS ON LOSODE
                        </div>
                      </div>
                      {/* <div className="dashboard-body-bold-numbers-item dashboard-body-bold-numbers-item-sold">
                        <div className="dashboard-body-bold-numbers-item-number">
                          {listing?.growthRate}
                          <span>
                            <svg
                              style={{ verticalAlign: "middle" }}
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 19V5"
                                stroke="#95C645"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M5 12L12 5L19 12"
                                stroke="#95C645"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                        </div>
                        <div className="dashboard-body-bold-numbers-item-text">
                          MONTHLY GROWTH RATE
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="dashboard-body-quick-action-container">
                    <div className="dashboard-body-quick-action-title">
                      Quick Actions
                    </div>
                    <div className="dashboard-body-quick-action-button-container">
                      <Button
                        icon={
                          <PlusOutlined
                            style={{
                              fontSize: !isTabletOrMobile ? "19px" : "15px",
                            }}
                          />
                        }
                        className="dashboard-body-quick-action-button"
                        onClick={() => history.push("/add-seller")}
                      >
                        Add New Designer
                      </Button>
                      <Button
                        className="dashboard-body-quick-action-button"
                        onClick={() => history.push("/seller-orders")}
                      >
                        Find Designer Order
                      </Button>
                      <Button
                        className="dashboard-body-quick-action-button"
                        onClick={() => history.push("/activate-stores")}
                      >
                        Activate Stores
                      </Button>
                      <Button
                        className="dashboard-body-quick-action-button"
                        onClick={() => history.push("/publish-stores")}
                      >
                        Publish Stores
                      </Button>

                      <Button
                        icon={
                          <PlusOutlined
                            style={{
                              fontSize: !isTabletOrMobile ? "19px" : "15px",
                            }}
                          />
                        }
                        className="dashboard-body-quick-action-button"
                        onClick={() =>
                          history.push("/add-product", {
                            brand: "",
                            sellerId: "",
                          })
                        }
                      >
                        List A New Item
                      </Button>
                      {/* <Button
                        className="dashboard-body-quick-action-button"
                        // onClick={() => navigate("/list-item")}
                      >
                        Block Seller
                      </Button> */}
                      <Button
                        className="dashboard-body-quick-action-button"
                        onClick={() => history.push("/manage-listings/new")}
                      >
                        Approve Listings
                      </Button>
                    </div>
                  </div>
                  <div className="dashboard-body-alert-box-container">
                    <div className="dashboard-body-alert-box">
                      <div className="dashboard-body-alert-box-title">
                        {pending?.newBrands} New Applications Awaiting Approval.{" "}
                        <span
                          className="dashboard-body-alert-box-action"
                          onClick={() => history.push("/activate-stores")}
                        >
                          Click To Approve Now
                        </span>
                      </div>
                    </div>
                    <div className="dashboard-body-alert-box">
                      <div className="dashboard-body-alert-box-title">
                        {pending?.newListing} New Listings Awaiting Approval.{" "}
                        <span
                          className="dashboard-body-alert-box-action"
                          onClick={() => history.push("/manage-listings/new")}
                        >
                          Click To Approve Now
                        </span>
                      </div>
                    </div>
                    <div className="dashboard-body-alert-box">
                      <div className="dashboard-body-alert-box-title">
                        {pending?.newListing} New Orders.{" "}
                        <span
                          className="dashboard-body-alert-box-action"
                          onClick={() => history.push("/manage-listings/new")}
                        >
                          Click to View Now
                        </span>
                      </div>
                    </div>
                    <div className="dashboard-body-alert-box">
                      <div className="dashboard-body-alert-box-title">
                        {pending?.newListing} New Photoshoots Orders.{" "}
                        <span
                          className="dashboard-body-alert-box-action"
                          onClick={() => history.push("/manage-listings/new")}
                        >
                          Click to View Now
                        </span>
                      </div>
                    </div>
                    {/* <div className="dashboard-body-alert-box">
                      <div className="dashboard-body-alert-box-title alert-box-dispute">
                        Aqua Teal Organza Frilly Dress by Patrick Cavanni has
                        been flagged.{" "}
                        <span className="dashboard-body-alert-box-action">
                          Click to resolve dispute
                        </span>
                      </div>
                    </div> */}
                  </div>
                  <div className="dashboard-body-orders-section">
                    <div className="dashboard-body-orders-title">
                      New Orders
                    </div>
                    {/* <div
                    className="dashboard-body-events-subtitle"
                    style={{ marginBottom: "24px" }}
                  >
                    Go to manage orders to view all of your orders and tell us
                    when the orders have been sent out so that we can let your
                    buyers know
                  </div> */}

                    <div className="dashboard-body-content-listings-container">
                      {newOrders?.length > 0 ? (
                        newOrders.map((order) => (
                          <OrderView
                            key={order.sale_id}
                            product={order}
                            type="Dashboard"
                          />
                        ))
                      ) : (
                        <div className="no-listings-to-show">
                          <div>
                            <div>There are currently no new orders</div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="dashboard-body-community-container">
                    <div className="dashboard-body-community-title">
                      We Currently have 24,000 Sellers in the Losode Seller
                      Community
                    </div>
            
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <HelpFeedback />

      <PhoneVerificationModal
        modalVisible={phoneVerifyModalVisible}
        setModalVisible={setPhoneVerifyModalVisible}
      /> */}

        <style jsx="true">{`
          .ant-layout-content {
            margin: 0 24px 24px;
          }
          .site-layout .site-layout-background {
            padding: 0px !important;
            background: transparent !important;
          }
          .dashboard-body-content-header,
          .dashboard-body-bold-numbers-container-contain,
          .dashboard-body-quick-action-container,
          .dashboard-body-orders-section {
            padding: 24px;
            background: white;
          }
          .dashboard-container {
            font-family: DomaineSansText-Light;
          }

          .manage-listing-all-content {
            position: relative;
            margin-top: 16px;
          }
          .dashboard-coverall-container {
          }
          .dashboard-body-main {
          }
          .dashboard-adspace {
            background-image: url("images/seller-stats-banner.png");
            height: 211px;
            background-repeat: no-repeat;
            width: 80%;
            background-position: bottom;
            background-color: black;
            margin: auto;
          }
          .dashboard-body-content-header-container {
            width: 100%;
          }
          .dashboard-body-content-header {
            display: flex;
            justify-content: space-between;
          }
          .dashboard-body-content-header-title {
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            width: 100%;
          }
          .dashboard-body-content-header-subtitle {
            font-size: 14px;
            color: #707070;
          }
          .dashboard-body-bold-numbers-container {
            display: flex;
            gap: 16px;
            width: 100%;
          }
          .dashboard-body-bold-numbers-item {
            width: 32%;
            background: black;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 30px 10px;
          }
          .dashboard-body-bold-numbers-item-number {
            font-family: "DomaineSansText-Black";
            font-size: 24px;
          }
          .dashboard-body-bold-numbers-item-text {
            font-size: 12px;
            text-transform: uppercase;
          }
          .dashboard-body-content-listings-container {
            display: flex;
            flex-wrap: wrap;
          }
          .dashboard-body-alert-box-container {
            margin-bottom: 50px;
          }
          .dashboard-body-alert-box {
            margin: 16px 0;
            background: #800000;
            color: white;
            padding: 15px;
            width: 100%;
          }
          .dashboard-body-alert-box-title {
            font-size: 14px;
          }
          .dashboard-body-alert-box-action {
            cursor: pointer;
            font-size: 14px;
            text-decoration: underline;
          }
          .alert-box-dispute {
            font-size: 14px;
          }
          .dashboard-body-orders-section {
            margin-bottom: 50px;
          }
          .dashboard-body-orders-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 24px;
            padding-left: 5px;
          }
          .dashboard-body-community-container {
            background: #ffecb3;
            padding: 16px 10px;
            width: 100%;
            margin-bottom: 50px;
          }
          .dashboard-body-community-title {
            font-size: 20px;
            font-weight: bold;
          }
          .dashboard-body-events-section {
            margin-bottom: 50px;
          }
          .dashboard-body-events-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          .dashboard-body-events-subtitle {
            margin-bottom: 24px;
            width: 90%;
          }
          .dashboard-body-partners-section {
            margin-bottom: 50px;
          }
          .dashboard-body-quick-action-button {
            height: 48px;
            background: black;
            color: white;
            font-size: 16px;
            width: 225px;
          }
          .ant-select-item-option-selected:not(
              .ant-select-item-option-disabled
            ) {
            background: white;
          }
          .dashboard-body-link {
            text-decoration: underline;
            color: black;
          }
          .dashboard-store-link-section {
            margin-top: 24px;
            background: rgba(0, 0, 0, 0.04);
            padding: 25px 10px;
            width: 100%;
          }
          .dashboard-store-link-title {
            font-size: 18px;
            margin-bottom: 8px;
            font-weight: bold;
          }
          .dashboard-store-link-subtitle {
            width: 90%;
          }
          .dashboard-events-image {
            width: auto;
            height: 200px;
          }
          .dashboard-partners-image {
            width: auto;
            height: 200px;
          }
          .dashboard-body-partners-images-container {
            display: flex;
            gap: 30px;
          }
          .dashboard-body-quick-action-container {
            margin-bottom: 50px;
          }
          .dashboard-body-quick-action-button-container {
            display: flex;
            gap: 16px;
            flex-wrap: wrap;
          }
          .dashboard-body-quick-action-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          .brand-button {
            background: #800000;
          }

          @media (max-width: 640px) {
            .dashboard-coverall-container {
              margin-left: 0;
            }
            .dashboard-adspace {
              background-image: url(images/seller-stats-banner.png);
              height: 120px;
              background-repeat: no-repeat;
              width: 100%;
              background-position: center;
              background-color: black;
              margin: auto;
              background-size: cover;
            }

            .hello-store-mobile {
              padding: 0px 16px 16px;
              font-weight: bold;
              font-size: 18px;
            }
            .dashboard-body-main {
              padding: 0 16px;
              padding-bottom: 40px;
              margin-right: 0;
            }
            .dashboard-body-content-header-container {
              display: flex;
              align-items: center;
            }
            .dashboard-body-content-header-title {
              font-size: 18px;
            }
            .dashboard-body-quick-action-button {
              padding: 0 16px;
              height: 40px;
              font-size: 14px;
              margin-top: auto;
            }
            .dashboard-body-bold-numbers-item {
              width: auto;
              justify-content: center;
              padding: 16px;
              text-align: center;
            }
            .dashboard-body-bold-numbers-container {
              gap: 8px;
              margin-bottom: 24px;
              margin-top: 0px;
              flex-wrap: wrap;
            }
            .dashboard-body-bold-numbers-item-number {
              font-size: 16px;
            }
            .dashboard-body-bold-numbers-item-text {
              font-size: 10px;
            }
            .dashboard-body-alert-box {
              padding: 16px 10px;
              margin-bottom: 24px;
            }
            .dashboard-body-community-container {
              padding: 16px 10px;
              margin-bottom: 24px;
            }
            .dashboard-body-events-subtitle {
              margin-bottom: 16px;
              width: 100%;
              font-size: 14px;
            }
            .dashboard-body-orders-section {
              margin-bottom: 24px;
            }
            .dashboard-partners-image {
              width: auto;
              height: 120px;
            }
            .dashboard-body-partners-images-container {
              gap: 8px;
            }
            .dashboard-store-link-section {
              padding: 16px 10px;
              margin-bottom: 24px;
            }
            .dashboard-events-image {
              width: auto;
              height: 300px;
            }
            .process-icon-container {
              padding: 8px;
              border-radius: 50%;
              border: 1px solid #800000;
              width: 32px;
              height: 32px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
            .process-icon-div-mobile {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 24px;
              margin: 16px 0;
            }
            .process-icon {
              color: #800000;
            }
            .dashboard-body-content-listings-container {
              display: block;
              margin-bottom: 40px;
            }
            .dashboard-body-bold-numbers-item-earned {
              width: 100%;
            }
            .dashboard-body-bold-numbers-item-listed,
            .dashboard-body-bold-numbers-item-sold {
              flex: 2;
            }
          }
        `}</style>
      </div>
    </Dashboard>
  );
};

export default SellerDashboard;
